import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useGlobalState } from "../../../context/GlobalStateProvider";

// Library
import { Drawer, Menu } from "antd";

function MobileMenu({ onOpenMobileMenu, onHandleCloseMobileMenu }) {
  const { isLoggedIn } = useGlobalState();

  const items = [
    {
      key: "shop",
      label: "Shop",
      children: [
        {
          key: "mctEssentials",
          label: <NavLink to="/mct/essentials">MCT ESSENTIALS</NavLink>,
        },
        {
          key: "mctLeanEssentials",
          label: (
            <NavLink to="/mct/leanessentials">
              MCT <span className="fw-300">LEAN</span>ESSENTIALS
            </NavLink>
          ),
        },
      ],
    },
    {
      key: "why",
      label: "Why Monolith Nutrition?",
      children: [
        {
          key: "whyMonolith",
          label: (
            <NavLink to="/about/why-monolith-nutrition">
              Why Monolith Nutrition?
            </NavLink>
          ),
        },
        {
          key: "absolutely-mct",
          label: (
            <NavLink to="/about/why-mct">
              Absolutely MCT for today and tomorrow
            </NavLink>
          ),
        },
        {
          key: "essentials",
          label: <NavLink to="/">MCT Essentials</NavLink>,
        },
        {
          key: "leanessentials",
          label: (
            <NavLink to="/about/leanessentials">
              MCT <span className="fw-300">LEAN</span>ESSENTIALS
            </NavLink>
          ),
        },
      ],
    },
    {
      key: "live",
      label: "Live Life & Learn",
      children: [
        {
          key: "community",
          label: <NavLink to="/community-blog">Community Blog</NavLink>,
        },
      ],
    },
    {
      key: "getInTouch",
      label: "Get In Touch!",
      children: [
        {
          key: "becomeAff",
          label: (
            <NavLink to="/lifestyle-affiliate">
              Become a lifestyle affiliate
            </NavLink>
          ),
        },
        {
          key: "contact",
          label: <NavLink to="/contact">Contact Us</NavLink>,
        },
      ],
    },
    // {
    //   type: "divider",
    // },
    {
      key: "account",
      label: (
        <>
          {!isLoggedIn() && (
            <Link
              className="btn btn-primary btn-large text-white flex j-c-center a-i-center"
              to="/login"
            >
              Login
            </Link>
          )}
          {isLoggedIn() && (
            <Link
              className="btn btn-primary text-white flex j-c-center a-i-center"
              to="/account"
            >
              See Account
            </Link>
          )}
        </>
      ),
    },
  ];
  return (
    <Drawer
      className="menuDrawer custom-drawer"
      title="Menu"
      onClose={onHandleCloseMobileMenu}
      open={onOpenMobileMenu}
      size="large"
      placement="left"
    >
      <div className="menuContainer">
        <Menu onClick={onHandleCloseMobileMenu} mode="inline" items={items} />
      </div>

      {/* <Divider />

      <div className="flex gap-20 a-i-center p-x-24">
        <div>Your account</div>
        <Link
          className="text-underline text-red"
          to="/login"
          onClick={onHandleCloseMobileMenu}
        >
          Login
        </Link>
      </div> */}
    </Drawer>
  );
}

export default MobileMenu;
