import React from "react";

function SachetBtmImg({ img }) {
  return (
    <div className="h-300 full-width">
      <img
        className="full-width full-height object-cover"
        src={img}
        alt="sachet"
      />
    </div>
  );
}

export default SachetBtmImg;
