import React from "react";

function FloatAddToCart({ handleAddToCart }) {
  return (
    <div className="addToCartFloatContainer">
      <button
        className="btn btn-primary addToCartFloatButton"
        onClick={handleAddToCart}
      >
        Add To Cart
      </button>
    </div>
  );
}

export default FloatAddToCart;
