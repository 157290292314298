import React from "react";

function ProductBox({ item }) {
  return (
    <div className={"productBox"}>
      <img src={item.img} alt={item.name} />
      {item.element}
    </div>
  );
}

export default ProductBox;
