import React from "react";

function SachetIntro({ introArray, children }) {
  return (
    <div className="rethinkContainer">
      <div className="font-bold text-uppercase m-b-40 fs-35 text-center">
        {children}
      </div>

      <div className="rethinkSection">
        {introArray.map((item) => (
          <div
            className={`rethinkCol ${
              item.altDirection ? "specialDirection" : ""
            }`}
            key={item.desc}
          >
            <div className={`rethinkText`}>
              {item.title}
              <div className="fw-300">{item.desc}</div>
            </div>

            <img className={`rethinkImg `} src={item.img} alt={item.title} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SachetIntro;
