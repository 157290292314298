import React from "react";
import LazyLoad from "react-lazy-load";

function MoreGreatPpl({ moreGreatPpl }) {
  return (
    <>
      <div className="font-bold text-uppercase m-b-40 fs-35 text-center">
        More <span className="text-red">Great</span> people and experiences
      </div>

      <div className="moreGreatPplGrid">
        {moreGreatPpl.map((ppl) => (
          <div className="bg-white fw-300 min-h-500" key={ppl.desc}>
            {/* <img
              className="full-width h-250 object-cover m-b-10"
              src={ppl.img}
              alt="great exp"
            /> */}
            <LazyLoad offset={100}>
              <video className="full-width m-b-10" controls poster={ppl.poster}>
                <source src={ppl.video} type="video/mp4" />
              </video>
            </LazyLoad>

            <div className="p-10">
              <div className="m-b-20">{ppl.desc}</div>
              <div className="font-bold">{ppl.name}</div>
              <div>{ppl.address}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default MoreGreatPpl;
