import React, { Fragment, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { NavLink } from "react-router-dom";

import { DownOutlined } from "@ant-design/icons";

function TopbarMenu({
  element,
  onOpenMenu,
  onHandleOpenMenu,
  onOpenSubMenu,
  onHandleOpenSubMenu,
  onSetOpenSubMenu,
  isScrollingUp,
}) {
  const topbarMenuRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleOpenTopbarMenu = (e) => {
      if (!e.target.closest(`.${topbarMenuRef?.current?.className}`)) {
        onHandleOpenMenu(null);
        onSetOpenSubMenu(false);
      }
    };

    document.addEventListener("click", handleOpenTopbarMenu);

    return () => {
      document.removeEventListener("click", handleOpenTopbarMenu);
    };
  }, [topbarMenuRef, onHandleOpenMenu, onSetOpenSubMenu]);

  return (
    <div
      className="topbarMenuItem"
      onClick={() => onHandleOpenMenu(element.value)}
      ref={topbarMenuRef}
    >
      <div
        className={`menuTitle ${onOpenMenu === element.value ? "active" : ""}`}
      >
        {element.title}
        <DownOutlined className="arrowIcon" />
      </div>
      <div
        className={`menuChild ${onOpenMenu !== element.value ? "d-none" : ""} ${
          location.pathname === "/" ? "text-black" : "text-white"
        } ${isScrollingUp ? "text-white" : "text-white"}`}
      >
        {element.children.map((item, index) => (
          <Fragment key={`menuChild-${index}`}>
            {!item?.grandchildren && (
              <NavLink to={item.link}>{item.name}</NavLink>
            )}

            {item?.grandchildren && item?.grandchildren?.length !== 0 && (
              <>
                <div
                  className={`${onOpenSubMenu ? "active" : ""} grandChildTitle`}
                  onClick={(e) => onHandleOpenSubMenu(e)}
                >
                  {item.name}
                </div>
                <ul className={`${onOpenSubMenu ? "" : "d-none"} p-l-45`}>
                  {item?.grandchildren?.map((gItem, gIndex) => (
                    <Fragment key={`menuGrandChild-${gIndex}`}>
                      <NavLink to={gItem.link}>{gItem.name}</NavLink>
                    </Fragment>
                  ))}
                </ul>
              </>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default TopbarMenu;
