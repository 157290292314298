import React from "react";

function ShortsItem({ short }) {
  const { title, img } = short;

  return (
    <div className="p-relative" key={title}>
      <img className="full-width" src={img} alt={title} />
      <div className="p-absolute bottom-20 left-20 text-uppercase text-white font-bold max-w-200 fs-40">
        {title}
      </div>
    </div>
  );
}

export default ShortsItem;
