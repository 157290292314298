import React from "react";

// Components
import Banner from "../components/Banner";

// NEW
import bannerImg from "../assets/images/banner/lean-banner.jpg";
import homeBtmImg from "../assets/images/home/lean-btm.jpg";
import threeSachets from "../assets/images/home/3-lean-sachets.png";

import cocoaImg from "../assets/images/home/sachets/lean/cocoa.png";
import postbiotics from "../assets/images/home/sachets/lean/synbiotics.png";
import coconutImg from "../assets/images/home/sachets/lean/coconut.png";
import wheyImg from "../assets/images/home/sachets/lean/whey.png";
import coconutFibreImg from "../assets/images/home/sachets/lean/coconut-fibre.png";

import proRecommendImg from "../assets/images/home/professional.jpg";

import happyUserImg from "../assets/images/home/great-exp.jpg";
import rethinkImg1 from "../assets/images/home/rethink/lean/rethink-1.jpg";
import rethinkImg2 from "../assets/images/home/rethink/lean/rethink-2.jpg";
import rethinkImg3 from "../assets/images/home/rethink/lean/rethink-3.jpg";

import moreGreat1 from "../assets/videos/lean-more-great/vid-1.mp4";
import moreGreat2 from "../assets/videos/lean-more-great/vid-2.mp4";
import moreGreat3 from "../assets/videos/lean-more-great/vid-3.mov";

import varietyCombo from "../assets/images/home/combo/variety.png";
import essentialsCombo from "../assets/images/home/combo/essentials.png";

import coreBenefitImg1 from "../assets/images/home/lean/core-benefits/benefit-1.png";
import coreBenefitImg2 from "../assets/images/home/lean/core-benefits/benefit-2.png";
import coreBenefitImg3 from "../assets/images/home/lean/core-benefits/benefit-3.png";

import bgCoconutTree from "../assets/images/home/lean/bg-coconut-trees.jpg";
import leanBetterLivingImg from "../assets/images/home/lean/better-living.jpg";

import { PlusOutlined } from "@ant-design/icons";
import SachetBtmImg from "../components/Home/SachetBtmImg";
import SachetIngredient from "../components/Home/SachetIngredient";
import DiffTable from "../components/Home/DiffTable";
import SachetIntro from "../components/Home/SachetIntro";
import { Link } from "react-router-dom";
import GreatExpBetterPpl from "../components/Home/GreatExpBetterPpl";
import MoreGreatPpl from "../components/Home/MoreGreatPpl";
import ComboPackages from "../components/Home/ComboPackages";
import mctFinalVideo from "../assets/videos/MCT Final.mp4";

const sachetsIngredients = [
  { title: "Cocoa powder", body: "", img: cocoaImg },
  {
    title: "Postbiotics",
    body: "",
    img: postbiotics,
  },
  {
    title: "100% USDA certified organic coconut mct",
    body: "2g per sachet",
    img: coconutImg,
  },
  { title: "Whey Protein Concentrate", body: "15g per sachet", img: wheyImg },
  {
    title: "Coconut Dietary Fibre",
    body: "From organic coconut",
    img: coconutFibreImg,
  },
];

const panelStyle = {
  marginBottom: 20,
  background: "#000",
  borderRadius: "0",
  border: "none",
};

const collapseData = [
  {
    key: "1",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Enhance Gut Microbiome</div>
        <div className="betterLivingItemSubtitle">
          Improve digestion and absorption.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Build & Maintain Muscle</div>
        <div className="betterLivingItemSubtitle">
          Increase muscle mass and manage healthy weight.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Improved Brain Fuel</div>
        <div className="betterLivingItemSubtitle">
          Energise and fuel healthy brain function.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "4",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Induced Thermogenesis</div>
        <div className="betterLivingItemSubtitle">
          Improve metabolism for better weight management.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "5",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Reverse Leaky Gut</div>
        <div className="betterLivingItemSubtitle">
          Create the ideal environment for healthy gut.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
];

const happyUsers = [
  {
    img: happyUserImg,
    title: (
      <>
        <div>A sachet with me</div>
        <div>wherever i go!</div>
      </>
    ),
    desc: `"There's always a Monolith Nutrition MCT Essentials sachet that conveniently goes with me to work. Easy to take and tastes great!"`,
    address: "Kuala Lumpur, Malaysia",
    username: "Happy User",
  },
];

const rethinkMct = [
  {
    img: rethinkImg1,
    title: (
      <div className="text-uppercase font-bold fs-25">
        <span className="text-red">Mixes</span> a lean and mean flavourful shake
      </div>
    ),
    desc: "MCT that mixes oh so easy! Mix, shake or blend a sachet into water or milk to make a drink that we confidently say can rival favorite",
    altDirection: false,
  },
  {
    img: rethinkImg2,
    title: (
      <div className="text-uppercase font-bold fs-25">
        Made <span className="text-red">Chic</span> and{" "}
        <span className="text-red">convenient</span> for mct goodness on the go
      </div>
    ),
    desc: "Whisk a sachet (or even the whole small box!) with you whether to the gym or to work for on-demand anytime MCT and protein goodness!",
    altDirection: true,
  },
  {
    img: rethinkImg3,
    title: (
      <div className="text-uppercase font-bold fs-25">
        <span className="text-red">Hearty</span> think and addictively tasty
      </div>
    ),
    desc: "We didn't name this flavour and tast profile as Chocolate Rapture for nothing! You will be craving every mix and moment!",
    altDirection: false,
  },
];

const moreGreatPpl = [
  {
    video: moreGreat1,
    desc: `"Monolith Nutrition MCT Essentials gives me the energy I need for the day. Perfect to complement my low carb lifestyle."`,
    name: "Happy User",
    address: "Kuala Lumpur, Malaysia",
  },
  {
    video: moreGreat2,
    desc: `"Tastes so good that I cannot believe it's actually a healthy drink!"`,
    name: "Happy User",
    address: "Kuala Lumpur, Malaysia",
  },
  {
    video: moreGreat3,
    desc: `"Clear head for great games. I support Monolith Nutrition!"`,
    name: "Happy User",
    address: "Kuala Lumpur, Malaysia",
  },
];

const tableData = [
  { desc: "MCT from only organic coconut", others: "Sometimes" },
  { desc: "Concentrated MCT in powder form", others: "Rarely" },
  { desc: "Formulated with whey protein", others: "Rarely" },
  { desc: "No soy and green pea protein", others: "Rarely" },
  {
    desc: "Mixes well with cold and room temperature water and drinks",
    others: "Rarely",
  },
  { desc: "Convenient chic sachet instead of bottle or tub", others: "None" },
  {
    desc: "No unnecessary fillers to aid mixability or to save cost",
    others: "Rarely",
  },
  { desc: "Gut-friendly for daily consumption", others: "Sometimes" },
];

const comboPackages = [
  {
    title: "Variety Combo",
    desc: "Mix it up with MCT and Whey Protein!",
    img: varietyCombo,
    price: "x,xxx",
  },
  {
    title: "Essentials Combo",
    desc: "4-months supply of MCT goodness!",
    img: essentialsCombo,
    price: "X,XXX",
  },
];

const coreBenefits = [
  {
    img: coreBenefitImg1,
    title: (
      <div className="font-bold fs-25 text-uppercase">
        Gut
        <br />
        Health
      </div>
    ),
    body: "Aid and supplement the conditions for healthy gut microbone.",
  },
  {
    img: coreBenefitImg2,
    title: (
      <div className="font-bold fs-25 text-uppercase">
        Build & Maintain <br />
        Muscle
      </div>
    ),
    body: "Achieve your weight managment goals through improved metabolism.",
  },
  {
    img: coreBenefitImg3,
    title: (
      <div className="font-bold fs-25 text-uppercase">
        Cognitive <br />
        Health
      </div>
    ),
    body: "Fuel andd naturally improve healthy brain function.",
  },
];

function AboutLeanEssentials() {
  return (
    <>
      <div className="homePage">
        <Banner img={bannerImg} type="lean" />

        <div className="leanListenExp text-white">
          <div className="container">
            <div className="leanRecommend">
              <div className="flex flex-col gap-10 max-w-450">
                <div className="font-bold text-uppercase fs-40">
                  Listen to the great experiences!
                </div>
                <div className="fw-300 fs-20">
                  We combined the benefits of MCT and Whey Protein Concentrate.
                  Few have tried something like this before!
                </div>
              </div>

              <div className="recommendImg">
                <video className="full-width" controls poster={proRecommendImg}>
                  <source src={mctFinalVideo} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>

        <div className="p-y-80">
          <div className="container">
            <div className="font-bold text-uppercase m-b-40 fs-35 text-center">
              <div>
                The <span className="text-red">Core</span> benefits of
              </div>
              <div>
                Monolith Nutrition MCT <span className="fw-300">Lean</span>
                Essentials
              </div>
            </div>

            <div className="coreBenefitsGrid">
              {coreBenefits.map((benefit, idx) => (
                <div className="text-center" key={`coreBenefit-${idx}`}>
                  <img
                    className="full-width max-w-200 m-b-20"
                    src={benefit.img}
                    alt={benefit.title}
                  />

                  <div className="flex flex-col gap-10">
                    {benefit.title}
                    <div className="fw-300">{benefit.body}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="p-y-80 grayCoconutBg"
          style={{ backgroundImage: `url(${bgCoconutTree})` }}
        >
          <div className="container">
            <div className="font-bold text-uppercase m-b-40 fs-35 text-center">
              <span className="text-red">Better living</span> one sachet at a
              time
            </div>

            <div className="leanBetterLivingGrid m-b-40">
              <div className="betterLivingAccordion">
                {collapseData.map((data) => (
                  <div className="betterLivingAccordionItem" key={data.key}>
                    {data.label}
                    <PlusOutlined className="plusIcon" />
                  </div>
                ))}
              </div>

              <img
                className="full-width full-height object-cover"
                src={leanBetterLivingImg}
                alt="MCT LEANESSENTIALS"
              />
            </div>

            <div className="flex j-c-center a-i-center">
              <Link
                to="/mct/leanessentials"
                className="btn btn-primary btn-large text-uppercase"
              >
                More Benefits
              </Link>
            </div>
          </div>
        </div>

        <div className="p-y-100">
          <SachetIngredient
            titleImg={threeSachets}
            swiperArray={sachetsIngredients}
          >
            <div>
              <div>
                <span className="text-red">Power</span> in
              </div>
              <div>
                Every <span className="text-red">Pack</span>
              </div>
            </div>
          </SachetIngredient>
        </div>

        <div className="bg-dark-gray p-y-80">
          <div className="container">
            <GreatExpBetterPpl happyUsers={happyUsers} />
          </div>
        </div>

        <div className="bg-black p-y-80 text-white">
          <div className="container">
            <DiffTable tableData={tableData} title="lean" />
          </div>
        </div>

        <div className="container p-y-100">
          <SachetIntro introArray={rethinkMct}>
            <span className="text-red">Daily</span> MCT you actually will enjoy
          </SachetIntro>
        </div>

        <div className="bg-dark-gray p-y-80">
          <div className="container">
            <MoreGreatPpl moreGreatPpl={moreGreatPpl} />
          </div>
        </div>

        <div className="p-y-80">
          <ComboPackages comboPackages={comboPackages} />
        </div>

        <SachetBtmImg img={homeBtmImg} />
      </div>
    </>
  );
}

export default AboutLeanEssentials;
