import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getUserProfile } from "../ducks/actions";

import { useGlobalState } from "../context/GlobalStateProvider";

import { Spin } from "antd";

export default function UserDataInfo() {
  const { loading, isLoggedIn } = useGlobalState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(getUserProfile());
    }
  }, [isLoggedIn, dispatch]);

  return <Spin size="large" spinning={loading} fullscreen={true} />;
}
