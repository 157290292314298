import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { categoryContent } from "../configs/communityBlogConstant";
import lifeStyleImg3 from "../assets/images/lifestyle-03.jpg";
import { AccessTimeOutlined } from "@mui/icons-material";
import SocialMedia from "../components/CommunityBlogItem/SocialMedia";

const validPath = categoryContent.mct.map((item) => item.path);
const currentUrl = encodeURIComponent(window.location.href);

function CommunityBlogItem() {
  const paramName = useParams().blog;
  const navigate = useNavigate();

  const blogContent = categoryContent.mct.find(
    (item) => item.path === paramName
  );

  const {
    author: { name, profileImg, about },
    categories,
    article,
    banner,
  } = blogContent;

  useEffect(() => {
    if (!validPath.includes(paramName)) {
      navigate("/page-not-found", { replace: true });
      return;
    }
  }, [paramName, navigate]);

  return (
    <div className="communityBlogItemPage">
      <div className="blueSection">
        <div className="container">
          <div className="header p-t-50">
            <div className="fw-300 m-b-10">Community Blog</div>
            <div className="font-bold fs-30 text-uppercase">
              {blogContent?.title}
            </div>
          </div>
        </div>
      </div>

      <div className="contentSection" style={{ marginTop: "-50px" }}>
        <div className="container">
          <img
            className="full-width p-relative z-index-1 max-h-450 object-cover"
            src={banner}
            alt="banner"
          />

          <div className="blogWritten p-y-20 text-gray">
            <div className="blogWrittenInfo">
              <div>
                Written and shared by{" "}
                <span className="text-red fw-500">{name}</span>
              </div>
              <div className="divider">|</div>
              <div className="flex gap-5 a-i-center">
                <AccessTimeOutlined
                  style={{
                    width: "25px",
                    height: "25px",
                    color: "#777",
                  }}
                />
                {"? min read"}
              </div>
            </div>

            <SocialMedia url={currentUrl} />
          </div>

          <div>{article}</div>

          <hr className="m-y-30" />

          <div className="text-gray m-b-20 font-italic fw-300">
            Stayed tuned for more insights, ideas, and engaging thoughts that
            will take us on a journey where the destination is a highly
            rewarding life. Stay healthy and happy out there!
          </div>

          <div className="categorySection text-gray m-b-20">
            <div className="flex a-i-center gap-20">
              <div>Categories</div>
              {categories.map((category) => (
                <div
                  className={`primary-bg text-white p-x-10 p-y-5 b-r-5 cursor-pointer fw-300 fs-14`}
                  key={category}
                >
                  {category}
                </div>
              ))}
            </div>

            <SocialMedia url={currentUrl} />
          </div>

          <div className="p-x-20 p-y-30 bg-gray">
            <div className="m-b-20 fs-20 fw-300 aboutAuthorTitle">
              About the Author
            </div>
            <div className="aboutAuthorBody">
              <img className="max-w-150" src={profileImg} alt={name} />
              <div className="aboutAuthorBodyDesc">
                <div>{name}</div>
                <div className="fw-300 text-gray">{about}</div>
                <div className="text-red cursor-pointer">
                  More articles by this author
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`beachSection p-relative`}>
        <div
          className={"beachImg"}
          style={{ backgroundImage: `url("${lifeStyleImg3}")` }}
        ></div>
      </div>
    </div>
  );
}

export default CommunityBlogItem;
