import React from "react";

// Components
import Banner from "../components/Banner";

// NEW
import bannerImg from "../assets/images/banner/banner.jpg";
import homeBtmImg from "../assets/images/home/home-btm.jpg";
import threeSachets from "../assets/images/home/3-sachets.png";

import acaciaImg from "../assets/images/home/sachets/essentials/acacia.png";
import lemonImg from "../assets/images/home/sachets/essentials/lemon.png";
import coconutImg from "../assets/images/home/sachets/essentials/coconut.png";
import postbiotics from "../assets/images/home/sachets/essentials/synbiotics.png";
import vitamineImg from "../assets/images/home/sachets/essentials/vitamin-e.png";

import happyUserImg from "../assets/images/home/great-exp.jpg";
import rethinkImg1 from "../assets/images/home/rethink/rethink-1.jpg";
import rethinkImg2 from "../assets/images/home/rethink/rethink-2.jpg";
import rethinkImg3 from "../assets/images/home/rethink/rethink-3.jpg";

import proRecommendImg from "../assets/images/home/professional.jpg";

import moreGreat1 from "../assets/videos/more-great/vid-1.mp4";
import moreGreat2 from "../assets/videos/more-great/vid-2.mp4";
import moreGreat3 from "../assets/videos/more-great/vid-3.mp4";
import moreGreat4 from "../assets/videos/more-great/vid-4.mov";

import moreGreatImg1 from "../assets/images/home/more-great/more-great-1.jpg";
import moreGreatImg2 from "../assets/images/home/more-great/more-great-2.jpg";
import moreGreatImg3 from "../assets/images/home/more-great/more-great-3.jpg";
import moreGreatImg4 from "../assets/images/home/more-great/more-great-4.jpg";

import varietyCombo from "../assets/images/home/combo/variety.png";
import essentialsCombo from "../assets/images/home/combo/essentials.png";

import { PlusOutlined } from "@ant-design/icons";
import SachetBtmImg from "../components/Home/SachetBtmImg";
import SachetIngredient from "../components/Home/SachetIngredient";
import DiffTable from "../components/Home/DiffTable";
import SachetIntro from "../components/Home/SachetIntro";
import GreatExpBetterPpl from "../components/Home/GreatExpBetterPpl";
import MoreGreatPpl from "../components/Home/MoreGreatPpl";
import ComboPackages from "../components/Home/ComboPackages";
import mctFinalVideo from "../assets/videos/MCT Final.mp4";

const sachetsIngredients = [
  { title: "Acacia Fiber / Gum Arabic", body: "", img: acaciaImg },
  {
    title: "Lemon Flavouring infused with vitamin c",
    body: "100% all-natural flavour",
    img: lemonImg,
  },
  {
    title: "100% USDA certified organic coconut mct",
    body: "3.5g per sachet",
    img: coconutImg,
  },
  { title: "Postbiotics", body: "", img: postbiotics },
  { title: "Tocotrienol Vitamin E", body: "20mg", img: vitamineImg },
];

const panelStyle = {
  marginBottom: 20,
  background: "#000",
  borderRadius: "0",
  border: "none",
};

const collapseData = [
  {
    key: "1",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Enhance Gut Microbiome</div>
        <div className="betterLivingItemSubtitle">
          Improve digestion and absorption.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Healthy Weight Management</div>
        <div className="betterLivingItemSubtitle">
          Support your weight management goals.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Better Brain Fuel</div>
        <div className="betterLivingItemSubtitle">
          Energise and fuel cognitive function.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "4",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">
          Burn Fat Through Induced Thermogenesis
        </div>
        <div className="betterLivingItemSubtitle">
          Improve metabolism for natural weight loss.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "5",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">
          Supports Healthy Brain Function
        </div>
        <div className="betterLivingItemSubtitle">
          From short-term cognitive benefits to improved working memory.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "6",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Reverse Leaky Gut</div>
        <div className="betterLivingItemSubtitle">
          Create the ideal enviroment for healthy gut for better health.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "7",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">
          Improved Digestion and absorption
        </div>
        <div className="betterLivingItemSubtitle">
          It's not just what you put in, but what you absorb.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "8",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">
          Aid and manage epilepsy, alzheimer's disease and autism
        </div>
        <div className="betterLivingItemSubtitle">
          Science-based benefits to help manage and alleviate conditions.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "9",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">
          Supplement your skin care and beauty routine
        </div>
        <div className="betterLivingItemSubtitle">
          Inherently natural properties that support healthy skin.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "10",
    label: (
      <div className="text-white text-center">
        <div className="betterLivingItemTitle">Jumpstart towards ketosis</div>
        <div className="betterLivingItemSubtitle">
          Support your goals and shortcut to healthy ketosis.
        </div>
      </div>
    ),
    children: (
      <div className="text-white">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur,
        repellendus voluptates. Vel, eaque itaque! Assumenda, aperiam delectus.
        Corrupti necessitatibus perferendis sint delectus sit et placeat iste
        voluptas, exercitationem dicta nemo.
      </div>
    ),
    style: panelStyle,
  },
];

const happyUsers = [
  {
    img: happyUserImg,
    title: (
      <>
        <div>A sachet with me</div>
        <div>wherever i go!</div>
      </>
    ),
    desc: `"There's always a Monolith Nutrition MCT Essentials sachet that conveniently goes with me to work. Easy to take and tastes great!"`,
    address: "Kuala Lumpur, Malaysia",
    username: "Happy User",
  },
];

const rethinkMct = [
  {
    img: rethinkImg1,
    title: (
      <div className="text-uppercase font-bold fs-25">
        <span className="text-red">Mixes</span> so well in cold or hot water...
        or with your favourite beverage!
      </div>
    ),
    desc: "Add a sachet into your water or your favorite beverage whether hot or cold - MCT that mixes oh so easy!",
    altDirection: false,
  },
  {
    img: rethinkImg2,
    title: (
      <div className="text-uppercase font-bold fs-25">
        Made <span className="text-red">Chic</span> and{" "}
        <span className="text-red">convenient</span> for mct goodness on the go
      </div>
    ),
    desc: "Take a sachet (or even the whole small box) with you for that MCT goodness even when you are on the go!",
    altDirection: true,
  },
  {
    img: rethinkImg3,
    title: (
      <div className="text-uppercase font-bold fs-25">
        Bold <span className="text-red">all-natural</span> taste and ingredients
      </div>
    ),
    desc: "All-natural ingredients formulated with a bold taste profile that leaves you craving for the next sachet!",
    altDirection: false,
  },
];

const moreGreatPpl = [
  {
    video: moreGreat1,
    desc: `"Monolith Nutrition MCT Essentials gives me the energy I need for the day. Perfect to complement my low carb lifestyle."`,
    name: "Happy User",
    address: "Kuala Lumpur, Malaysia",
    poster: moreGreatImg1,
  },
  {
    video: moreGreat2,
    desc: `"Tastes so good that I cannot believe it's actually a healthy drink!"`,
    name: "Happy User",
    address: "Kuala Lumpur, Malaysia",
    poster: moreGreatImg2,
  },
  {
    video: moreGreat3,
    desc: `"Supports my goals towards better health, Monlith Nutrition MCT Essentials goes with me everyday."`,
    name: "Happy User",
    address: "Kuala Lumpur, Malaysia",
    poster: moreGreatImg3,
  },
  {
    video: moreGreat4,
    desc: `"Clear head for great games. I support Monolith Nutrition!"`,
    name: "Happy User",
    address: "Kuala Lumpur, Malaysia",
    poster: moreGreatImg4,
  },
];

const tableData = [
  { desc: "MCT from only organic coconut", others: "Sometimes" },
  { desc: "Concentrated MCT in powder form", others: "Rarely" },
  { desc: "Only C8 and C10 MCT", others: "Rarely" },
  { desc: "All-natural flavouring", others: "Rarely" },
  {
    desc: "Mixes well with cold and room temperature water and drinks",
    others: "Rarely",
  },
  { desc: "Convenient chic sachet instead of bottle or tub", others: "None" },
  {
    desc: "No unnecessary fillers to aid mixability or to save cost",
    others: "Rarely",
  },
  { desc: "Gut-friendly for daily consumption", others: "Sometimes" },
];

const comboPackages = [
  {
    title: "Variety Combo",
    desc: "Mix it up with MCT and Whey Protein!",
    img: varietyCombo,
    price: "x,xxx",
  },
  {
    title: "Essentials Combo",
    desc: "4-months supply of MCT goodness!",
    img: essentialsCombo,
    price: "X,XXX",
  },
];

function Home() {
  return (
    <>
      <div className="homePage">
        <Banner img={bannerImg} type="essentials" />
        <div className="container">
          <div className="essentialRecommend">
            <div className="recommendImg overflow-hidden">
              <video className="full-width" controls poster={proRecommendImg}>
                <source src={mctFinalVideo} type="video/mp4" />
              </video>
            </div>
            <div className="flex flex-col gap-10 max-w-400">
              <div className="font-bold text-uppercase fs-35">
                Professionally Recommended
              </div>
              <div className="fw-300 fs-20">
                Active ingredients in every sachet that truly matter and make
                sense to professionals.
              </div>
            </div>
          </div>
        </div>

        <div className="bg-dark-gray p-y-80">
          <div className="container">
            <div className="font-bold text-uppercase m-b-40 fs-35 text-center">
              <span className="text-red">Better living</span> one sachet at a
              time
            </div>

            <div className="betterLivingAccordion">
              {/* <Collapse
                items={collapseData}
                bordered={false}
                borderRadius={0}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusOutlined style={{ color: "white", fontSize: 25 }} />
                  ) : (
                    <PlusOutlined style={{ color: "white", fontSize: 25 }} />
                  )
                }
              /> */}
              {collapseData.map((data) => (
                <div className="betterLivingAccordionItem" key={data.key}>
                  {data.label}
                  <PlusOutlined className="plusIcon" />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="p-y-80">
          <SachetIngredient
            titleImg={threeSachets}
            swiperArray={sachetsIngredients}
          >
            <div>
              <div>
                <span className="text-red">Big</span> things come in
              </div>
              <div>
                <span className="text-red">Small</span> sachets
              </div>
            </div>
          </SachetIngredient>
        </div>

        <div className="bg-dark-gray p-y-80">
          <div className="container">
            <GreatExpBetterPpl happyUsers={happyUsers} />
          </div>
        </div>

        <div className="bg-black p-y-80 text-white">
          <div className="container">
            <DiffTable tableData={tableData} title="big" />
          </div>
        </div>

        <div className="container p-y-100">
          <SachetIntro introArray={rethinkMct}>
            <span className="text-red">Rethink</span> the way you think mct
          </SachetIntro>
        </div>

        <div className="bg-dark-gray p-y-80">
          <div className="container">
            <MoreGreatPpl moreGreatPpl={moreGreatPpl} />
          </div>
        </div>

        <div className="p-y-80">
          <ComboPackages comboPackages={comboPackages} />
        </div>

        <SachetBtmImg img={homeBtmImg} />
      </div>
    </>
  );
}

export default Home;
