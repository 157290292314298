import React, { useState } from "react";
import sustainImg from "../assets/images/sustainability-01.jpg";
import {
  categories,
  categoryContent,
  shorts,
} from "../configs/communityBlogConstant";
import CategoryContentItem from "../components/CommunityBlog/CategoryContentItem";
import CategoryList from "../components/CommunityBlog/CategoryList";
import ShortsItem from "../components/CommunityBlog/ShortsItem";

import blogMainBanner from "../assets/images/community-blog/blog-main-banner.jpg";

function CommunityBlog() {
  const [activeCategory, setActiveCategory] = useState(categories[0].value);

  return (
    <div className="communityBlogPage p-relative">
      <div className="communityBlogBlueSection">
        <div className="sectionContainer container">
          <div className="sectionContainerText">
            <div className="fw-300 m-b-5">Live Life & Learn</div>
            <div className="text-uppercase fs-40 font-bold m-b-20">
              <span className="text-red">Lifestyle</span> <span>Community</span>
            </div>

            <div className="fw-300">
              Welcome to the Monolith Nutrition community space where a little
              bit of shared knowledge, insight, and experiences goes a long way
              towards good health and better living.
              <br />
              <br />
              You will only find positivity and encouragemnet here. Live life
              and learn together!
            </div>
          </div>
          <div className="sectionContainerImg">
            <img
              className="full-width"
              src={blogMainBanner}
              alt="life is good"
            />
          </div>
        </div>
      </div>

      <div className="communityBlogWelcomeSection">
        <div className="sectionContainer container">
          <div className="titleContent m-b-20">
            <div className="font-bold m-b-10">
              <span className="fs-50 text-red">Welcome</span>
              <br />
              <span className="fs-25">to a new lifestyle!</span>
            </div>

            <div className="fw-300 fs-18">
              A little bit here goes a long way!
            </div>
          </div>

          <div className="categories m-b-30">
            <div className="categorySelection">
              Categories:
              <div className="flex gap-10 a-i-center">
                {categories.map((category) => (
                  <CategoryList
                    category={category}
                    activeCategory={activeCategory}
                    setActiveCategory={setActiveCategory}
                    key={category.value}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="categoryContent">
            <div className="categoryContentGrid">
              {categoryContent[activeCategory].map((content, index) => (
                <CategoryContentItem
                  content={content}
                  key={`${activeCategory}-${index}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="communityBlogShortsSection">
        <div className="container">
          <div className="font-bold fs-30 m-b-20">Shorts and shots!</div>
          <div className="fw-300 m-b-50">
            A dose of good health and better living!
          </div>

          <div className="communityBlogShortGrid">
            {shorts.map((short) => (
              <ShortsItem short={short} key={short.title} />
            ))}
          </div>
        </div>
      </div>

      <div className={`beachSection p-relative`}>
        <div
          className={"beachImg"}
          style={{ backgroundImage: `url("${sustainImg}")` }}
        ></div>
      </div>
    </div>
  );
}

export default CommunityBlog;
