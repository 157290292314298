import React from "react";
import { CheckOutlined } from "@ant-design/icons";

import monolithLogo from "../../assets/images/logo.png";

function DiffTable({ tableData, title }) {
  return (
    <>
      <div className="font-bold text-uppercase m-b-40 fs-35 text-center">
        The <span className="text-red">{title}</span> difference
      </div>

      <div className="diff-table">
        <div className="diff-table-row">
          <div
            className="p-15"
            style={{ height: "100%", borderBottom: "1px solid white" }}
          ></div>
          <div
            className="text-center bg-green p-15"
            style={{
              borderRight: "1px solid white",
              height: "100%",
              borderBottom: "1px solid white",
            }}
          >
            <img
              className="max-w-120"
              src={monolithLogo}
              alt="monolith nutrition"
            />
          </div>
          <div
            className="text-center p-15 flex j-c-center a-i-center"
            style={{
              borderRight: "1px solid white",
              borderTop: "1px solid white",
              height: "100%",
              borderBottom: "1px solid white",
            }}
          >
            Others
          </div>
        </div>

        {tableData.map((data) => (
          <div className="diff-table-row" key={data.desc}>
            <div
              className="p-15 full-height"
              style={{ borderBottom: "1px solid white" }}
            >
              {data.desc}
            </div>
            <div
              className="text-center p-15 bg-green"
              style={{
                borderRight: "1px solid white",
                height: "100%",
                borderBottom: "1px solid white",
              }}
            >
              <CheckOutlined
                style={{
                  fontSize: "20px",
                }}
              />
            </div>
            <div
              className="text-red text-center p-15 flex j-c-center a-i-center"
              style={{
                borderRight: "1px solid white",
                height: "100%",
                borderBottom: "1px solid white",
              }}
            >
              {data.others}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default DiffTable;
