import React from "react";

function ComboPackages({ comboPackages }) {
  return (
    <div className="combo-container">
      <div className="font-bold text-uppercase m-b-40 fs-35 text-center">
        <div>
          Earn attractive <span className="text-red">incentives</span> while
        </div>
        <div>
          <span className="text-red">Enjoying</span> better living
        </div>
      </div>

      <div className="fw-300 text-center m-b-40">
        Automatically qualify as a Monolith Nutrition Lifestyle Affiliate with
        any of the combo packages below that helps you enjoy and maintain a
        consistent and healthy MCT regimen.
      </div>

      <div className="comboPackageGrid">
        {comboPackages.map((combo) => (
          <div className="comboPackageGridItem" key={combo.title}>
            <div className="font-bold text-uppercase fs-25">{combo.title}</div>
            <div className="fw-300">{combo.desc}</div>

            <img
              className="full-width max-w-250"
              src={combo.img}
              alt={combo.title}
            />

            <div className="font-bold fs-30">RM{combo.price}</div>

            <button className="btn btn-primary btn-large text-uppercase">
              Add to Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ComboPackages;
