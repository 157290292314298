import React from "react";

// Images
import paymentOptionsImg from "../../assets/images/payment-options-01.png";
// import malaysiaIcon from "../../assets/images/msia-icon.png";

// Icons
import { FieldTimeOutlined } from "@ant-design/icons";

function ProductShippingInfo() {
  return (
    <>
      <div className="productShippingInfo m-b-10">
        <div className="flex gap-10 a-i-start">
          <FieldTimeOutlined className="fs-30" />
          <div>
            <div className="fw-550">WM Delivery: 5 - 7 Working Days</div>
            <div className="paragraph">EM Delivery: 5 - 7 Working Days</div>
          </div>
        </div>
      </div>
      <img src={paymentOptionsImg} alt="payment options" />
    </>
  );
}

export default ProductShippingInfo;
