import React from "react";

// Antd
import { Divider } from "antd";

// Image
import logoSmall from "../../../assets/images/logo_small.png";

function ContentDivider({ element, index, arr }) {
  return (
    <>
      <div className={"contentDividerContainer"}>
        {element.img && (
          <img className={"banner"} src={element.img} alt={element.title} />
        )}
        <div className={`desc text-center`}>
          <div className="big-title m-b-30">{element.name}</div>
          {element?.subtitle && (
            <div className="big-sub-title text-red m-b-30">
              {element.subtitle}
            </div>
          )}
          <div className="body paragraph">{element.desc}</div>
        </div>
      </div>
      {index + 1 !== arr.length && (
        <Divider
          style={{
            borderColor: "#000",
          }}
        >
          <img src={logoSmall} alt="logo" width={25} />
        </Divider>
      )}
    </>
  );
}

export default ContentDivider;
