import React from "react";

// Community blog start
import testimonialImg1 from "../assets/images/testimonials/testimonial-01.jpg";
import testimonialImg2 from "../assets/images/testimonials/testimonial-02.jpg";
import testimonialImg3 from "../assets/images/testimonials/testimonial-03.jpg";
import testimonialImg4 from "../assets/images/testimonials/testimonial-04.jpg";
import testimonialImg5 from "../assets/images/testimonials/testimonial-05.jpg";

import kenImg from "../assets/images/community-blog/ken.png";
import philipImg from "../assets/images/community-blog/philip.png";
import elderYogaImg from "../assets/images/community-blog/elder-yoga.jpg";
import fitMaleImg from "../assets/images/community-blog/fit-male.jpg";
import coconutImg from "../assets/images/community-blog/coconut.png";
import sachetImg from "../assets/images/community-blog/sachet.png";
import kenBanner from "../assets/images/community-blog/ken-banner.jpg";
import philipBanner from "../assets/images/community-blog/philip-banner.jpg";
// Community blog end

// Community Blog Start
export const categories = [
  { label: "MCT", value: "mct" },
  { label: "Lifestyle", value: "lifestyle" },
];

export const categoryContent = {
  mct: [
    {
      title: "Mind Your Business",
      author: {
        name: "Ken Khang Nanyi",
        about:
          "Ken Khang’s talents and heart is in the industry of marketing and creatives. He has been actively engaged in fitness and resistance training since 2004. Throughout his journey in fitness, he has dabbled with all sorts of workout regimens, nutritional supplements, performance enhancing drugs (hush-hush...), diets, traditional medicines, and more, and he loves to share positivity, optimism, and good vibes with others. He believes health and wellness should never be a second choice – it is the only choice. He currently resides between Malaysia and Thailand.",
        profileImg: kenImg,
      },
      desc: "Your body literally runs and thrives like a business - YOUR business. Make a distinction, yet draw upon its similarities for better and more fulfilling living.",
      path: "mind-your-business",
      categories: ["LifeStyle", "Beginners"],
      article: (
        <div className="fw-300 text-gray">
          <div>
            Whatever career, profession, or task you undertake, you are
            essentially doing some form of business. You put in the work
            necessary to achieve your goals so that you get rewarded for your
            efforts. You strive to be competent and relevant in your job, field,
            and industry in order to deliver value. You learn to work smart and
            use leverage so that you can get more done everyday. You work with
            the intention of creating passive income, or for a pension or reward
            scheme that keeps you comfortable for retirement.
            <br />
            <br />
            Now, let us enlighten ourselves as to how similar building and
            maintaining a healthy body is to building a business.
            <br />
            You put in place a lifestyle that helps you stay fit physically and
            mentally. You strive to commit to exercise so that you stay healthy
            for a better quality of life. You learn to leverage on knowledge and
            nutrition so that your efforts become greater. You build a
            foundation for good health towards enjoying a long and fulfilling
            life that is free from sickness and disease.
            <br />
            <br />
            See the similarities in concept and essence? Your body is literally
            a business – <span className="font-bold">YOUR</span> business.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Building a solid foundation</div>
            A successful business or career thrives when a solid foundation
            manifests itself from your knowledge, skills and abilities. With
            this foundation, you open up the ability to create sustainable
            action and systems for further income streams.
            <br />
            <br />
            Your overall well-being and quality of life relies on a healthy
            foundation to flourish upon. Neglecting foundational health risks
            weakening the immune system, increasing susceptibility to sickness
            and disease, and giving way to chronic illnesses.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">
              Staying informed and relevant in a changing environment
            </div>
            The business landscape has evolved and individuals and organisations
            must adapt in order to stay relevant. It’s cliche but it’s true when
            they say change is the only constant. But there are fundamentals
            that consistently work and remain relevant since time immemorial,
            and that is creating value through productive outputs.
            <br />
            <br />
            With new advances, research, and knowledge in health and wellness,
            it is important to stay in the loop of current information that is
            relevant to good health and improving your quality of life through
            wellness.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Use it or lose it</div>
            Use your knowledge, skills and abilities to create value; or do not
            and let it eventually become forgotten and go to waste. The muscles
            of our body when not frequently used eventually atrophy, leading to
            infirmities and even sarcopenia. Our mind and brain deteriorates
            through time without mental stimulation across the thought and
            learning process, leading to a feeble mind and even senility.
            <br />
            <br />
            Make effort and take action to use your mind and muscles, or
            eventually lose it.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-500 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={elderYogaImg}
                alt="elder doing yoga"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Maintaining healthy muscle is vital for longevity and a more
                fulfilling quality of life.
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">
              Aspire to create passive reward streams
            </div>
            We strive to achieve some form of passive income or an appropriate
            nest egg that allows us to maintain a desired lifestyle, enjoy
            comfortably long way down our golden years, or leave behind a
            legacy. When a profitable foundation and sustainable system is in
            place, income continues well into the times even when you stop
            working. The natural process of thermogenesis occurs in our body
            throughout the day even while we are resting or sleeping. This is
            part of our body’s metabolism and when harnessed, creates an ideal
            and natural environment for optimum weight loss and healthy physical
            function. outputs.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-500 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={fitMaleImg}
                alt="fit male"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                {
                  "Thermogenesis is literally passive behind the scenes fat and energy burn towards a fit body (and body of your dreams!)"
                }
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Your takeaway for a great day</div>
            In both business and life in general, making small efforts and
            lifestyle tweaks today definitely helps you enjoy tomorrow and
            beyond so much more. So mind your business starting from today!
          </div>
        </div>
      ),
      banner: kenBanner,
    },
    {
      title: "Our belief towards good health & better living",
      author: {
        name: "Philip Tan",
        about:
          "Philip Tan has more than 35 years of experience in the paper packaging industry, having been attached with Amcor and AMB Packaging. Since 2019, he has shifted his belief, mindset, and business towards environmental sustainability – wholeheartedly dedicating his actions towards advocating the importance of practising sustainability in everything we do. He is pictured here with Pidan (皮蛋), his lovely doggie. FYI, Pidan is Chinese for Century Egg. He currently resides in Malaysia and Singapore.",
        profileImg: philipImg,
      },
      desc: "You put more effort and heart into the things you do when you truly believe in it. We take this short opportunity to share our values and beliefs, and hope to convince you that Monolith Nutrition is your right choice for good health and better living.",
      path: "our-belief-towards-good-health-and-better-living",
      categories: ["About Us"],
      article: (
        <div className="fw-300 text-gray">
          <div>
            It’s one thing to do something, and another to truly believe it in.
            When we truly believe in something, we put all of our heart, mind,
            body, and soul into our actions and tasks. There’s just no faking
            it.
            <br />
            <br />
            Monolith Nutrition was the culmination of three years of business
            strategy brainstorming, nutritional research, product formulation
            and tweaking, bonding and team-building, and belief-driven passion.
            All of which resulted in the creation of a values and beliefs system
            that drives our decision-making towards the pursuit of delivering
            nutritional supplements that truly promote good health and better
            living across the lives that every humble sachet touches.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Good Health & Better Living</div>
            We are laser-focused in formulating foundational nutrition
            supplements that are high quality and of great value for good health
            and better living. Whether it is MCT derived from USDA certified
            organic coconuts, whey protein concentrate, coconut dietary fibre,
            European-sourced natural flavourings, or high bioavailability
            tocotrienol, expect the best ingredients used across strict
            formulation and manufacturing standards.
            <br />
            <br />
            Our flagship products MCT Essentials and MCT LeanEssentials
            represent high quality MCT nutritional supplements that do not break
            the bank and are suitable for long-term consumption.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Differentiation</div>
            Know that you are using nutritional supplements that are unique and
            stand out in their own way. We know that MCT is great for health,
            but we differentiate ourselves by standing out from the rest by
            packing as much coconut MCT rather than filler into every serving
            sachet. We are proud to have created a tantalising taste profile
            that makes you crave for the next sachet. We have painstakingly
            formulated an easy to mix MCT powdered product that mixes amazingly
            well with both cold and hot water or drinks – something that many
            find difficult without the use of fillers.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Sustainability</div>
            Our business actions and activities aim to reduce carbon footprint.
            Monolith Nutrition avoids using plastic wrap for our products, and
            our box paper packaging is derived from recycled materials and
            sustainable forestry. Product box printing utilises sustainable and
            eco-friendly vegetable-based inks. MCT Essentials’ small and chic
            box and sachet sizes use fewer resources, and we take it a step
            further with paper packaging carton boxes that fit as many products
            as possible to further reduce use of paper carton boxes.
            <br />
            <br />
            Monolith Nutrition’s sourced USDA certified organic coconuts – the
            prime ingredient used for our MCT – are sourced from suppliers who
            practise sustainable farming methods.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-300 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={coconutImg}
                alt="coconut"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Quality MCT sourced from USDA certified organic coconuts.
                Consume with confidence!
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Contemporary</div>
            <div>
              MCT Essentials and MCT LeanEssentials truly represents a modern
              and contemporary way to enjoy all the benefits of MCT in a
              convenient and enjoyable manner. Easily mixed with your favourite
              drinks – whether cold or hot – or blend the sachet’s contents of
              flavourful Lemon Blast powder or hearty Chocolate Rapture powder
              into a nutritious shake or smoothie that is not only super healthy
              but addictively tasty.
            </div>

            <br />
            <br />

            <div>
              Furthermore, in chic sachet form, take them on the go with you and
              consume at your convenience day or night, sunshine or rain! Even
              the box itself is small enough to bring along with you!
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-300 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={sachetImg}
                alt="MCT sachet"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Contemporary, chic and convenient sachets perfect for everyday
                free and easy or even on the go lifestyles.
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Innovation</div>
            <div>
              The fundamentals for healthy living has not changed and remains as
              simple and straightforward as it has always been. However, with
              new ideas, research, and advances in nutrition, exercise, modern
              medicine, and an overall improved quality of life, there are more
              options today than ever before when it comes to choosing the right
              solutions we need and desire to better improve health and
              wellness.
            </div>

            <br />
            <br />

            <div>
              With economies of scale, nutritional experience, an equipped
              facility, and technical know-how, Monolith Nutrition avoids the
              common OEM and ODM method of product formulation and production.
              We innovate across the design, formulation, and process of the
              product from start to finish, allowing us to customise and create
              MCT products that are unique and one of a kind.
            </div>

            <br />
            <br />

            <div>
              We wholeheartedly believe that you hold in your hands a
              nutritional supplement that will pay dividends to your overall
              health and wellness. Be confident that every MCT Essentials or MCT
              LeanEssentials sachet that you take gets you closer to good health
              and better living.
            </div>
          </div>
        </div>
      ),
      banner: philipBanner,
    },
  ],
  lifestyle: [
    {
      title: "Mind Your Business",
      author: {
        name: "Ken Khang Nanyi",
        about:
          "Ken Khang’s talents and heart is in the industry of marketing and creatives. He has been actively engaged in fitness and resistance training since 2004. Throughout his journey in fitness, he has dabbled with all sorts of workout regimens, nutritional supplements, performance enhancing drugs (hush-hush...), diets, traditional medicines, and more, and he loves to share positivity, optimism, and good vibes with others. He believes health and wellness should never be a second choice – it is the only choice. He currently resides between Malaysia and Thailand.",
        profileImg: kenImg,
      },
      desc: "Your body literally runs and thrives like a business - YOUR business. Make a distinction, yet draw upon its similarities for better and more fulfilling living.",
      path: "mind-your-business",
      categories: ["LifeStyle", "Beginners"],
      article: (
        <div className="fw-300 text-gray">
          <div>
            Whatever career, profession, or task you undertake, you are
            essentially doing some form of business. You put in the work
            necessary to achieve your goals so that you get rewarded for your
            efforts. You strive to be competent and relevant in your job, field,
            and industry in order to deliver value. You learn to work smart and
            use leverage so that you can get more done everyday. You work with
            the intention of creating passive income, or for a pension or reward
            scheme that keeps you comfortable for retirement.
            <br />
            <br />
            Now, let us enlighten ourselves as to how similar building and
            maintaining a healthy body is to building a business.
            <br />
            You put in place a lifestyle that helps you stay fit physically and
            mentally. You strive to commit to exercise so that you stay healthy
            for a better quality of life. You learn to leverage on knowledge and
            nutrition so that your efforts become greater. You build a
            foundation for good health towards enjoying a long and fulfilling
            life that is free from sickness and disease.
            <br />
            <br />
            See the similarities in concept and essence? Your body is literally
            a business – <span className="font-bold">YOUR</span> business.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Building a solid foundation</div>
            A successful business or career thrives when a solid foundation
            manifests itself from your knowledge, skills and abilities. With
            this foundation, you open up the ability to create sustainable
            action and systems for further income streams.
            <br />
            <br />
            Your overall well-being and quality of life relies on a healthy
            foundation to flourish upon. Neglecting foundational health risks
            weakening the immune system, increasing susceptibility to sickness
            and disease, and giving way to chronic illnesses.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">
              Staying informed and relevant in a changing environment
            </div>
            The business landscape has evolved and individuals and organisations
            must adapt in order to stay relevant. It’s cliche but it’s true when
            they say change is the only constant. But there are fundamentals
            that consistently work and remain relevant since time immemorial,
            and that is creating value through productive outputs.
            <br />
            <br />
            With new advances, research, and knowledge in health and wellness,
            it is important to stay in the loop of current information that is
            relevant to good health and improving your quality of life through
            wellness.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Use it or lose it</div>
            Use your knowledge, skills and abilities to create value; or do not
            and let it eventually become forgotten and go to waste. The muscles
            of our body when not frequently used eventually atrophy, leading to
            infirmities and even sarcopenia. Our mind and brain deteriorates
            through time without mental stimulation across the thought and
            learning process, leading to a feeble mind and even senility.
            <br />
            <br />
            Make effort and take action to use your mind and muscles, or
            eventually lose it.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-500 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={elderYogaImg}
                alt="elder doing yoga"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Maintaining healthy muscle is vital for longevity and a more
                fulfilling quality of life.
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">
              Aspire to create passive reward streams
            </div>
            We strive to achieve some form of passive income or an appropriate
            nest egg that allows us to maintain a desired lifestyle, enjoy
            comfortably long way down our golden years, or leave behind a
            legacy. When a profitable foundation and sustainable system is in
            place, income continues well into the times even when you stop
            working. The natural process of thermogenesis occurs in our body
            throughout the day even while we are resting or sleeping. This is
            part of our body’s metabolism and when harnessed, creates an ideal
            and natural environment for optimum weight loss and healthy physical
            function. outputs.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-500 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={fitMaleImg}
                alt="fit male"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                {
                  "Thermogenesis is literally passive behind the scenes fat and energy burn towards a fit body (and body of your dreams!)"
                }
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Your takeaway for a great day</div>
            In both business and life in general, making small efforts and
            lifestyle tweaks today definitely helps you enjoy tomorrow and
            beyond so much more. So mind your business starting from today!
          </div>
        </div>
      ),
      banner: kenBanner,
    },
    {
      title: "Our belief towards good health & better living",
      author: {
        name: "Philip Tan",
        about:
          "Philip Tan has more than 35 years of experience in the paper packaging industry, having been attached with Amcor and AMB Packaging. Since 2019, he has shifted his belief, mindset, and business towards environmental sustainability – wholeheartedly dedicating his actions towards advocating the importance of practising sustainability in everything we do. He is pictured here with Pidan (皮蛋), his lovely doggie. FYI, Pidan is Chinese for Century Egg. He currently resides in Malaysia and Singapore.",
        profileImg: philipImg,
      },
      desc: "You put more effort and heart into the things you do when you truly believe in it. We take this short opportunity to share our values and beliefs, and hope to convince you that Monolith Nutrition is your right choice for good health and better living.",
      path: "our-belief-towards-good-health-and-better-living",
      categories: ["About Us"],
      article: (
        <div className="fw-300 text-gray">
          <div>
            It’s one thing to do something, and another to truly believe it in.
            When we truly believe in something, we put all of our heart, mind,
            body, and soul into our actions and tasks. There’s just no faking
            it.
            <br />
            <br />
            Monolith Nutrition was the culmination of three years of business
            strategy brainstorming, nutritional research, product formulation
            and tweaking, bonding and team-building, and belief-driven passion.
            All of which resulted in the creation of a values and beliefs system
            that drives our decision-making towards the pursuit of delivering
            nutritional supplements that truly promote good health and better
            living across the lives that every humble sachet touches.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Good Health & Better Living</div>
            We are laser-focused in formulating foundational nutrition
            supplements that are high quality and of great value for good health
            and better living. Whether it is MCT derived from USDA certified
            organic coconuts, whey protein concentrate, coconut dietary fibre,
            European-sourced natural flavourings, or high bioavailability
            tocotrienol, expect the best ingredients used across strict
            formulation and manufacturing standards.
            <br />
            <br />
            Our flagship products MCT Essentials and MCT LeanEssentials
            represent high quality MCT nutritional supplements that do not break
            the bank and are suitable for long-term consumption.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Differentiation</div>
            Know that you are using nutritional supplements that are unique and
            stand out in their own way. We know that MCT is great for health,
            but we differentiate ourselves by standing out from the rest by
            packing as much coconut MCT rather than filler into every serving
            sachet. We are proud to have created a tantalising taste profile
            that makes you crave for the next sachet. We have painstakingly
            formulated an easy to mix MCT powdered product that mixes amazingly
            well with both cold and hot water or drinks – something that many
            find difficult without the use of fillers.
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Sustainability</div>
            Our business actions and activities aim to reduce carbon footprint.
            Monolith Nutrition avoids using plastic wrap for our products, and
            our box paper packaging is derived from recycled materials and
            sustainable forestry. Product box printing utilises sustainable and
            eco-friendly vegetable-based inks. MCT Essentials’ small and chic
            box and sachet sizes use fewer resources, and we take it a step
            further with paper packaging carton boxes that fit as many products
            as possible to further reduce use of paper carton boxes.
            <br />
            <br />
            Monolith Nutrition’s sourced USDA certified organic coconuts – the
            prime ingredient used for our MCT – are sourced from suppliers who
            practise sustainable farming methods.
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-300 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={coconutImg}
                alt="coconut"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Quality MCT sourced from USDA certified organic coconuts.
                Consume with confidence!
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Contemporary</div>
            <div>
              MCT Essentials and MCT LeanEssentials truly represents a modern
              and contemporary way to enjoy all the benefits of MCT in a
              convenient and enjoyable manner. Easily mixed with your favourite
              drinks – whether cold or hot – or blend the sachet’s contents of
              flavourful Lemon Blast powder or hearty Chocolate Rapture powder
              into a nutritious shake or smoothie that is not only super healthy
              but addictively tasty.
            </div>

            <br />
            <br />

            <div>
              Furthermore, in chic sachet form, take them on the go with you and
              consume at your convenience day or night, sunshine or rain! Even
              the box itself is small enough to bring along with you!
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="max-w-300 text-center m-l-auto m-r-auto">
              <img
                className="full-width m-b-10"
                src={sachetImg}
                alt="MCT sachet"
              />
              <div className="fw-300 text-gray fs-12 font-italic">
                Contemporary, chic and convenient sachets perfect for everyday
                free and easy or even on the go lifestyles.
              </div>
            </div>
          </div>

          <br />
          <br />

          <div>
            <div className="font-bold m-b-5">Innovation</div>
            <div>
              The fundamentals for healthy living has not changed and remains as
              simple and straightforward as it has always been. However, with
              new ideas, research, and advances in nutrition, exercise, modern
              medicine, and an overall improved quality of life, there are more
              options today than ever before when it comes to choosing the right
              solutions we need and desire to better improve health and
              wellness.
            </div>

            <br />
            <br />

            <div>
              With economies of scale, nutritional experience, an equipped
              facility, and technical know-how, Monolith Nutrition avoids the
              common OEM and ODM method of product formulation and production.
              We innovate across the design, formulation, and process of the
              product from start to finish, allowing us to customise and create
              MCT products that are unique and one of a kind.
            </div>

            <br />
            <br />

            <div>
              We wholeheartedly believe that you hold in your hands a
              nutritional supplement that will pay dividends to your overall
              health and wellness. Be confident that every MCT Essentials or MCT
              LeanEssentials sachet that you take gets you closer to good health
              and better living.
            </div>
          </div>
        </div>
      ),
      banner: philipBanner,
    },
  ],
};

export const shorts = [
  {
    img: testimonialImg1,
    title: "Mix it into your life!",
  },
  {
    img: testimonialImg2,
    title: "Energy for my exercise",
  },
  { img: testimonialImg3, title: "Amazing taste" },
  {
    img: testimonialImg4,
    title: "Clear head clear game",
  },
  { img: testimonialImg5, title: "Everyday goodness" },
];
// Community Blog End
