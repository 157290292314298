import React from "react";
import { Link } from "react-router-dom";
import { essentialProductId, leanProductId } from "./constants";

// Product Images Start
import essentialBox1 from "../assets/images/box_MCT Essentials_01.png";
import essentialBox2 from "../assets/images/MCT-Essentials-Panel-02.jpg";
import essentialBox3 from "../assets/images/MCT-Essentials-Panel-03.jpg";

import leanEssentialBox1 from "../assets/images/box_MCT LeanEssentials_01.png";
import leanEssentialBox2 from "../assets/images/MCT-LeanEssentials-Panel-02.jpg";
import leanEssentialBox3 from "../assets/images/MCT-LeanEssentials-Panel-03.jpg";

import essentialsFeature1Img from "../assets/images/essentials_feature_1.png";
import essentialsFeature2Img from "../assets/images/essentials_feature_2.png";
import essentialsFeature3Img from "../assets/images/essentials_feature_3.png";
import essentialsFeature4Img from "../assets/images/essentials_feature_4.png";
import essentialsFeature5Img from "../assets/images/essentials_feature_5.png";
import essentialsFeature6Img from "../assets/images/essentials_feature_6.png";

import leanFeature1Img from "../assets/images/Monolith Nutrition-MCT LeanEssentials-Sachet-Packs.png";
import leanFeature2Img from "../assets/images/mct-whey-powder-easy-mix.jpg";
import leanFeature3Img from "../assets/images/lean_feature_3.png";
import leanFeature4Img from "../assets/images/lean_feature_4.png";
import leanFeature5Img from "../assets/images/lean_feature_5.png";
import leanFeature6Img from "../assets/images/lean_feature_6.png";
// Product Images End

// TEST
// Product Benefits
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import DirectionsRunOutlinedIcon from "@mui/icons-material/DirectionsRunOutlined";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
// END TEST

export const isPro = "0";

// Product Start
export const productList = {
  essentials: {
    id: essentialProductId,
    path: "essentials",
    breadcrumbName: [
      { title: <Link to="/">Home</Link> },
      { title: <div>MCT ESSENTIALS</div> },
    ],
    productName: <div className="big-title m-b-5">MCT ESSENTIALS</div>,
    nameString: "MCT ESSENTIALS",
    productDescHead:
      "100% MCT from coconut with Postbiotics Proprietary Matrix",
    productDescBody:
      "Monolith Nutrition MCT (medium-chain triglycerides) ESSENTIALS is the core foundation from which you build your nutritional supplementation regimen upon. Each box contains 24 convenient ready to drink lifestyle sachets that improve gut health, weight management, and immune health.",
    price: 198,
    size: "20 / box",
    flavour: "Lemon Blast",
    productImages: [essentialBox1, essentialBox2, essentialBox3],
    productFeatureImages: [
      {
        img: essentialsFeature1Img,
        alt: "20 Convenient Lifestyle Sachets",
      },
      {
        img: essentialsFeature2Img,
        alt: "Tasty and Refreshing Lemon Blast Flavour",
      },
      {
        img: essentialsFeature3Img,
        alt: "Create Improved Gut Health & Microbiome, Promote Healthy Weight Management, Support Healthy Brain Function",
      },
      {
        img: essentialsFeature4Img,
        alt: "100% Coconut MCT",
      },
      {
        img: essentialsFeature5Img,
        alt: "The Latest Achievement In Gut Health",
      },
      {
        img: essentialsFeature6Img,
        alt: "Pairs Great with MCT LeanEssentials for Protein Intake",
      },
    ],
    accordionData: [
      {
        title: "Recommended Use",
        content: (
          <div className="recommendAccContent">
            <div className="accordionGridContent">
              <div className="paragraph title">Serving Size</div>
              <div className="paragraph content">5g</div>
              <div className="paragraph title">Servings Per Box</div>
              <div className="paragraph content">20 sachets</div>
              <div className="paragraph title">Serving Suggestions</div>
              <div className="paragraph content">
                <div className="m-b-20">
                  Take 1 – 2 sachets a day in the morning and/or evening,
                  preferably between meals on an empty stomach. Mix a sachet
                  with 250ml of cold or room temperature water.
                </div>
                <div>
                  MCT Essentials is designed for superior taste and mixability.
                  Add a sachet of MCT Essentials into your usual morning
                  smoothie shake, or with certain fruit juices to create a mixed
                  punch taste. Be creative, make it fun and mix it up! Check out
                  our community blog for ideas!
                </div>
              </div>
              <div className="paragraph title">Important Notes</div>
              <div className="paragraph content">
                Due to the natural fragility of the postbiotics matrix
                ingredients, do not mix with hot water.
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Ingredients",
        content: (
          <div className="ingredientAccContent">
            <div className="paragraph m-b-30">
              Coconut MCT (Medium-chain triglycerides), Acacia Fibre, Lemon
              Powder, Postbiotics, d-Alpha-Tocotrienol, Guar Gum (E412) as
              permitted stabilizer and Sucralose (E955) as permitted sweetener.
            </div>
            <div className="paragraph">
              All additives are of plant origin. Manufactured in a facility that
              processes dairy, eggs, soy, and nuts.
            </div>
          </div>
        ),
      },
      {
        title: "Supplement Facts",
        content: (
          <div className="supplementAccContent">
            <div className="paragraph m-b-10">Serving Size: 1 sachet (5g)</div>
            <div className="paragraph m-b-10">Amount Per Serving</div>
            <div className="accordionGridContent">
              <div className="paragraph title">Total Fat</div>
              <div className="paragraph content">1.1g</div>
              <div className="paragraph title p-l-10">Saturated Fat</div>
              <div className="paragraph content">1.1g</div>
              <div className="paragraph title p-l-10">Trans Fat</div>
              <div className="paragraph content">0g</div>
              <div className="paragraph title">Cholesterol</div>
              <div className="paragraph content">0.1mg</div>
              <div className="paragraph title">Sodium</div>
              <div className="paragraph content">5.2mg</div>
              <div className="paragraph title">Total Carbohydrates</div>
              <div className="paragraph content">3.4g</div>
              <div className="paragraph title p-l-10">Dietary Fiber</div>
              <div className="paragraph content">1.2g</div>
              <div className="paragraph title p-l-10">
                <div>Total Sugars</div>
                <div className="p-l-10">Includes 0.6 of Added Sugars</div>
              </div>
              <div className="paragraph content">0.9g</div>
              <div className="paragraph title">Protein</div>
              <div className="paragraph content">0g</div>
            </div>
          </div>
        ),
      },
    ],
  },
  leanessentials: {
    id: leanProductId,
    path: "leanessentials",
    breadcrumbName: [
      { title: <Link to="/">Home</Link> },
      { title: <div>MCT Lean Essentials</div> },
    ],
    productName: (
      <div className="big-title m-b-5">
        MCT <span className="fw-300">LEAN</span>ESSENTIALS
      </div>
    ),
    nameString: "MCT LEANESSENTIALS",
    productDescHead:
      "High protein ready to drink nutritional shake with 100% coconut MCT and a prebiotics matrix.",
    productDescBody:
      "Monolith Nutrition MCT (medium-chain triglycerides) LEANESSENTIALS is packed with whey protein concentrate (WPC80) goodness. Each box contains 15 convenient ready to drink lifestyle sachets power-packed with all the benefits of MCT and a superior protein source from whey.",
    price: 198,
    size: "15 / box",
    flavour: "Chocolate Rapture",
    productImages: [leanEssentialBox1, leanEssentialBox2, leanEssentialBox3],
    productFeatureImages: [
      {
        img: leanFeature1Img,
        alt: "15 Convenient Lifestyle Sachets",
        needBorder: true,
      },
      {
        img: leanFeature2Img,
        alt: "Hearty and Addictive Chocolate Rapture Flavour",
        needBorder: true,
      },
      {
        img: leanFeature3Img,
        alt: "Create Improved Gut Health & Microbiome, Promote Healthy Weight Management, Support Healthy Brain Function",
        needBorder: false,
      },
      {
        img: leanFeature4Img,
        alt: "100% Coconut MCT",
        needBorder: false,
      },
      {
        img: leanFeature5Img,
        alt: "The Latest Achievement In Gut Health",
        needBorder: false,
      },
      {
        img: leanFeature6Img,
        alt: "Pairs Strongly with Foundational MCT Essentials",
        needBorder: false,
      },
    ],
    accordionData: [
      {
        title: "Recommended Use",
        content: (
          <div className="recommendAccContent">
            <div className="accordionGridContent">
              <div className="paragraph title">Serving Size</div>
              <div className="paragraph content">40g</div>
              <div className="paragraph title">Servings Per Box</div>
              <div className="paragraph content">15 sachets</div>
              <div className="paragraph title">Serving Suggestions</div>
              <div className="paragraph content">
                Take 1 – 2 sachets a day. Best consumed before breakfast or
                between meals. Enjoy a nutritious and great tasting shake by
                mixing a sachet with 250 – 300ml of water, milk, tea or coffee.
                Designed for superior taste and mixability. Best enjoyed cold
                and chilled. Store product in a cool, dry place.
                <br />
                <br />
                Enjoy a hearty and nutritious shake by mixing a sachet with 250
                – 300ml of water, milk, tea or coffee. MCT LeanEssentials is
                designed to absolutely mix and taste great. Mix a sachet of MCT
                LeanEssentials into cold milk to enjoy with cereal, or blend
                into a delicious smoothie shake with your favourite fruits,
                oats, or nuts. The Chocolate Rapture flavour lends itself to
                adding a taste profile that rivals your favourite cocoa or
                coffee smoothie shake so go wild and be creative! Check out our
                community blog for fun (and more importantly, healthy) serving
                ideas!
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Ingredients",
        content: (
          <div className="ingredientAccContent">
            <div className="paragraph m-b-30">
              Whey Protein Concentrate, Cocoa Powder, Non-dairy Creamer, Sugar,
              Proprietary Coconut Dietary Fibre, Prebiotics
              (Fructooligosaccharide), Coconut MCT (Medium-chain triglycerides),
              Acacia Fibre, Guar Gum (INS 412) as permitted stabilizer,
              Sucralose (INS 955) as permitted sweetener and permitted
              flavoring.
            </div>
            <div className="paragraph">
              All additives are of plant origin. Manufactured in a facility that
              processes dairy, eggs, soy, and nuts.
            </div>
          </div>
        ),
      },
      {
        title: "Supplement Facts",
        content: (
          <div className="supplementAccContent">
            <div className="paragraph m-b-10">Serving Size: 1 sachet (40g)</div>
            <div className="paragraph m-b-10">Amount Per Serving</div>
            <div className="accordionGridContent">
              <div className="paragraph title">Total Fat</div>
              <div className="paragraph content">3.5g</div>
              <div className="paragraph title p-l-10">Saturated Fat</div>
              <div className="paragraph content">2.5g</div>
              <div className="paragraph title p-l-10">Trans Fat</div>
              <div className="paragraph content">0g</div>
              <div className="paragraph title">Cholesterol</div>
              <div className="paragraph content">33mg</div>
              <div className="paragraph title">Sodium</div>
              <div className="paragraph content">132mg</div>
              <div className="paragraph title">Total Carbohydrates</div>
              <div className="paragraph content">17.6g</div>
              <div className="paragraph title p-l-10">Dietary Fiber</div>
              <div className="paragraph content">6g</div>
              <div className="paragraph title p-l-10">
                <div>Total Sugars</div>
                <div className="p-l-10">Includes 0.6 of Added Sugars</div>
              </div>
              <div className="paragraph content">8.9g</div>
              <div className="paragraph title">Protein</div>
              <div className="paragraph content">15g</div>
            </div>
          </div>
        ),
      },
    ],
  },
};
// Product End

// Test
export const testProductList = {
  essentials: {
    id: essentialProductId,
    path: "essentials",
    breadcrumbName: [
      { title: <Link to="/">Home</Link> },
      { title: <div>MCT ESSENTIALS</div> },
    ],
    productName: <div className="big-title m-b-5">MCT ESSENTIALS</div>,
    nameString: "MCT ESSENTIALS",
    productDescHead:
      "100% MCT from coconut with Postbiotics Proprietary Matrix",
    productDescBody:
      "Monolith Nutrition MCT (medium-chain triglycerides) ESSENTIALS is the core foundation from which you build your nutritional supplementation regimen upon. Each box contains 24 convenient ready to drink lifestyle sachets that improve gut health, weight management, and immune health.",
    price: 198,
    size: "20 / box",
    flavour: "Lemon Blast",
    productImages: [
      "https://dummyimage.com/1440x1500/000/fff",
      "https://dummyimage.com/1440x1500/333/fff",
      "https://dummyimage.com/1440x1500/555/fff",
      "https://dummyimage.com/1440x1500/777/fff",
    ],
    productFeatureImages: [
      {
        img: essentialsFeature1Img,
        alt: "20 Convenient Lifestyle Sachets",
      },
      {
        img: essentialsFeature2Img,
        alt: "Tasty and Refreshing Lemon Blast Flavour",
      },
      {
        img: essentialsFeature3Img,
        alt: "Create Improved Gut Health & Microbiome, Promote Healthy Weight Management, Support Healthy Brain Function",
      },
      {
        img: essentialsFeature4Img,
        alt: "100% Coconut MCT",
      },
      {
        img: essentialsFeature5Img,
        alt: "The Latest Achievement In Gut Health",
      },
      {
        img: essentialsFeature6Img,
        alt: "Pairs Great with MCT LeanEssentials for Protein Intake",
      },
    ],
    accordionData: [
      {
        title: "Recommended Use",
        content: (
          <div className="recommendAccContent">
            <div className="accordionGridContent">
              <div className="paragraph title">Serving Size</div>
              <div className="paragraph content">5g</div>
              <div className="paragraph title">Servings Per Box</div>
              <div className="paragraph content">20 sachets</div>
              <div className="paragraph title">Serving Suggestions</div>
              <div className="paragraph content">
                <div className="m-b-20">
                  Take 1 – 2 sachets a day in the morning and/or evening,
                  preferably between meals on an empty stomach. Mix a sachet
                  with 250ml of cold or room temperature water.
                </div>
                <div>
                  MCT Essentials is designed for superior taste and mixability.
                  Add a sachet of MCT Essentials into your usual morning
                  smoothie shake, or with certain fruit juices to create a mixed
                  punch taste. Be creative, make it fun and mix it up! Check out
                  our community blog for ideas!
                </div>
              </div>
              <div className="paragraph title">Important Notes</div>
              <div className="paragraph content">
                Due to the natural fragility of the postbiotics matrix
                ingredients, do not mix with hot water.
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Ingredients",
        content: (
          <div className="ingredientAccContent">
            <div className="paragraph m-b-30">
              Coconut MCT (Medium-chain triglycerides), Acacia Fibre, Lemon
              Powder, Postbiotics, d-Alpha-Tocotrienol, Guar Gum (E412) as
              permitted stabilizer and Sucralose (E955) as permitted sweetener.
            </div>
            <div className="paragraph">
              All additives are of plant origin. Manufactured in a facility that
              processes dairy, eggs, soy, and nuts.
            </div>
          </div>
        ),
      },
      {
        title: "Supplement Facts",
        content: (
          <div className="supplementAccContent">
            <div className="paragraph m-b-10">Serving Size: 1 sachet (5g)</div>
            <div className="paragraph m-b-10">Amount Per Serving</div>
            <div className="accordionGridContent">
              <div className="paragraph title">Total Fat</div>
              <div className="paragraph content">1.1g</div>
              <div className="paragraph title p-l-10">Saturated Fat</div>
              <div className="paragraph content">1.1g</div>
              <div className="paragraph title p-l-10">Trans Fat</div>
              <div className="paragraph content">0g</div>
              <div className="paragraph title">Cholesterol</div>
              <div className="paragraph content">0.1mg</div>
              <div className="paragraph title">Sodium</div>
              <div className="paragraph content">5.2mg</div>
              <div className="paragraph title">Total Carbohydrates</div>
              <div className="paragraph content">3.4g</div>
              <div className="paragraph title p-l-10">Dietary Fiber</div>
              <div className="paragraph content">1.2g</div>
              <div className="paragraph title p-l-10">
                <div>Total Sugars</div>
                <div className="p-l-10">Includes 0.6 of Added Sugars</div>
              </div>
              <div className="paragraph content">0.9g</div>
              <div className="paragraph title">Protein</div>
              <div className="paragraph content">0g</div>
            </div>
          </div>
        ),
      },
    ],
  },
  leanessentials: {
    id: leanProductId,
    path: "leanessentials",
    breadcrumbName: [
      { title: <Link to="/">Home</Link> },
      { title: <div>MCT Lean Essentials</div> },
    ],
    productName: (
      <div className="big-title m-b-5">
        MCT <span className="fw-300">LEAN</span>ESSENTIALS
      </div>
    ),
    nameString: "MCT LEANESSENTIALS",
    productDescHead:
      "High protein ready to drink nutritional shake with 100% coconut MCT and a prebiotics matrix.",
    productDescBody:
      "Monolith Nutrition MCT (medium-chain triglycerides) LEANESSENTIALS is packed with whey protein concentrate (WPC80) goodness. Each box contains 15 convenient ready to drink lifestyle sachets power-packed with all the benefits of MCT and a superior protein source from whey.",
    price: 198,
    size: "15 / box",
    flavour: "Chocolate Rapture",
    productImages: [leanEssentialBox1, leanEssentialBox2, leanEssentialBox3],
    productFeatureImages: [
      {
        img: leanFeature1Img,
        alt: "15 Convenient Lifestyle Sachets",
        needBorder: true,
      },
      {
        img: leanFeature2Img,
        alt: "Hearty and Addictive Chocolate Rapture Flavour",
        needBorder: true,
      },
      {
        img: leanFeature3Img,
        alt: "Create Improved Gut Health & Microbiome, Promote Healthy Weight Management, Support Healthy Brain Function",
        needBorder: false,
      },
      {
        img: leanFeature4Img,
        alt: "100% Coconut MCT",
        needBorder: false,
      },
      {
        img: leanFeature5Img,
        alt: "The Latest Achievement In Gut Health",
        needBorder: false,
      },
      {
        img: leanFeature6Img,
        alt: "Pairs Strongly with Foundational MCT Essentials",
        needBorder: false,
      },
    ],
    accordionData: [
      {
        title: "Recommended Use",
        content: (
          <div className="recommendAccContent">
            <div className="accordionGridContent">
              <div className="paragraph title">Serving Size</div>
              <div className="paragraph content">40g</div>
              <div className="paragraph title">Servings Per Box</div>
              <div className="paragraph content">15 sachets</div>
              <div className="paragraph title">Serving Suggestions</div>
              <div className="paragraph content">
                Take 1 – 2 sachets a day. Best consumed before breakfast or
                between meals. Enjoy a nutritious and great tasting shake by
                mixing a sachet with 250 – 300ml of water, milk, tea or coffee.
                Designed for superior taste and mixability. Best enjoyed cold
                and chilled. Store product in a cool, dry place.
                <br />
                <br />
                Enjoy a hearty and nutritious shake by mixing a sachet with 250
                – 300ml of water, milk, tea or coffee. MCT LeanEssentials is
                designed to absolutely mix and taste great. Mix a sachet of MCT
                LeanEssentials into cold milk to enjoy with cereal, or blend
                into a delicious smoothie shake with your favourite fruits,
                oats, or nuts. The Chocolate Rapture flavour lends itself to
                adding a taste profile that rivals your favourite cocoa or
                coffee smoothie shake so go wild and be creative! Check out our
                community blog for fun (and more importantly, healthy) serving
                ideas!
              </div>
            </div>
          </div>
        ),
      },
      {
        title: "Ingredients",
        content: (
          <div className="ingredientAccContent">
            <div className="paragraph m-b-30">
              Whey Protein Concentrate, Cocoa Powder, Non-dairy Creamer, Sugar,
              Proprietary Coconut Dietary Fibre, Prebiotics
              (Fructooligosaccharide), Coconut MCT (Medium-chain triglycerides),
              Acacia Fibre, Guar Gum (INS 412) as permitted stabilizer,
              Sucralose (INS 955) as permitted sweetener and permitted
              flavoring.
            </div>
            <div className="paragraph">
              All additives are of plant origin. Manufactured in a facility that
              processes dairy, eggs, soy, and nuts.
            </div>
          </div>
        ),
      },
      {
        title: "Supplement Facts",
        content: (
          <div className="supplementAccContent">
            <div className="paragraph m-b-10">Serving Size: 1 sachet (40g)</div>
            <div className="paragraph m-b-10">Amount Per Serving</div>
            <div className="accordionGridContent">
              <div className="paragraph title">Total Fat</div>
              <div className="paragraph content">3.5g</div>
              <div className="paragraph title p-l-10">Saturated Fat</div>
              <div className="paragraph content">2.5g</div>
              <div className="paragraph title p-l-10">Trans Fat</div>
              <div className="paragraph content">0g</div>
              <div className="paragraph title">Cholesterol</div>
              <div className="paragraph content">33mg</div>
              <div className="paragraph title">Sodium</div>
              <div className="paragraph content">132mg</div>
              <div className="paragraph title">Total Carbohydrates</div>
              <div className="paragraph content">17.6g</div>
              <div className="paragraph title p-l-10">Dietary Fiber</div>
              <div className="paragraph content">6g</div>
              <div className="paragraph title p-l-10">
                <div>Total Sugars</div>
                <div className="p-l-10">Includes 0.6 of Added Sugars</div>
              </div>
              <div className="paragraph content">8.9g</div>
              <div className="paragraph title">Protein</div>
              <div className="paragraph content">15g</div>
            </div>
          </div>
        ),
      },
    ],
  },
};

export const productBenefits = [
  {
    icon: <WaterDropOutlinedIcon style={{ width: "30px", height: "30px" }} />,
    title: "Banish Visceral Fat",
    body: "Converts fat-storing White Adipose Tissue to fat-burning Brown Adipose Tissue and helps reshape difficult-to-lose areas.",
  },
  {
    icon: (
      <FavoriteBorderOutlinedIcon style={{ width: "30px", height: "30px" }} />
    ),
    title: "Lower LDL-Cholesterol",
    body: "Stimulates cholesterol-lowering effects by balancing triglycerides level and enhancing the breakdown of lipids.",
  },
  {
    icon: (
      <ElectricBoltOutlinedIcon style={{ width: "30px", height: "30px" }} />
    ),
    title: "Reverse Metabolic Syndrome",
    body: "Improves chronic conditions such as high blood pressure, type 2 diabetes, and heart diseases.",
  },
  {
    icon: (
      <DirectionsRunOutlinedIcon style={{ width: "30px", height: "30px" }} />
    ),
    title: "Eliminate Hormonal Obesity",
    body: "Balances hormonal production to boost the body’s metabolism and accelerates fatty acid oxidation.",
  },
  {
    icon: (
      <LocalFireDepartmentOutlinedIcon
        style={{ width: "30px", height: "30px" }}
      />
    ),
    title: "Reduce Inflammatory Markers",
    body: "Contains all-natural ingredients rich in antioxidants to suppress inflammation of body cells.",
  },
  {
    icon: (
      <HealthAndSafetyOutlinedIcon style={{ width: "30px", height: "30px" }} />
    ),
    title: "Support Liver Health",
    body: "Neutralizes free radicals, reverses the inflammatory damages caused by excessive fat storage, and alleviates NAFLD.",
  },
];

export const productBenefitsDetailsAccordion = [
  {
    title: (
      <div className="fw-550">
        <span className="text-gray">Lorem </span>
        <span>ipsum dolor</span>
      </div>
    ),
    content: (
      <div className="fw-300 m-b-50">
        <div className="m-b-50">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sint
          corrupti vero esse magnam ducimus commodi est, deserunt delectus non
          veritatis, totam aut blanditiis iure, officia inventore eveniet? Modi,
          excepturi corporis.
        </div>

        <div className="m-b-50">
          <div className="fw-550 m-b-20">Cold Thermogenesis:</div>
          <ul>
            <li>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla
              nihil saepe similique, voluptatem ullam a sequi, consequuntur
              dolor laudantium adipisci tempora! Omnis ducimus voluptatibus esse
              expedita maxime officiis aut et.
            </li>
            <li>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Recusandae, at voluptatibus. Sit reprehenderit, quam sint
              assumenda quisquam pariatur sed, maiores odio distinctio illum
              quas dignissimos necessitatibus iste similique! Animi, omnis?
            </li>
          </ul>
        </div>

        <div>
          <div className="fw-550 m-b-20">AMPK Pathway:</div>
          <ul>
            <li>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda
              repudiandae sed, quia fuga in nobis labore soluta sequi pariatur
              perferendis corrupti aperiam asperiores perspiciatis expedita a
              quas necessitatibus neque aliquam.
            </li>
            <li>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Delectus
              esse voluptates, officia rem quaerat qui dolor in, odio provident
              quae sunt a eveniet. Odio, iure veniam ratione distinctio quam
              tenetur.
            </li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    title: (
      <div className="fw-550">
        <span className="text-gray">Lorem </span>
        <span>ipsum dolor</span>
      </div>
    ),
    content: (
      <div className="fw-300">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sapiente ipsam
        eaque maxime sed, nam consectetur. Molestias nisi a numquam, accusantium
        non optio corporis qui praesentium, quasi ducimus explicabo. Vel,
        tempora.
      </div>
    ),
  },
  {
    title: (
      <div className="fw-550">
        <span className="text-gray">Lorem </span>
        <span>ipsum dolor</span>
      </div>
    ),
    content: (
      <div className="fw-300">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, expedita
        facere! Ipsa ipsam consectetur voluptates voluptatibus esse omnis
        repudiandae temporibus eum optio, quam perspiciatis quaerat! Minus
        debitis tempora corrupti aperiam?
      </div>
    ),
  },
  {
    title: (
      <div className="fw-550">
        <span className="text-gray">Lorem </span>
        <span>ipsum dolor</span>
      </div>
    ),
    content: (
      <div className="fw-300">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat odio
        sequi natus, pariatur excepturi animi in dolorem iste sit, aspernatur
        saepe voluptas, tempore iure alias placeat quasi eligendi quod aut?
      </div>
    ),
  },
  {
    title: (
      <div className="fw-550">
        <span className="text-gray">Lorem </span>
        <span>ipsum dolor</span>
      </div>
    ),
    content: (
      <div className="fw-300">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur
        doloribus ad quam eligendi maxime, nam soluta odit voluptatem, laborum
        libero rem voluptas nesciunt quaerat incidunt, facilis placeat animi in
        error.
      </div>
    ),
  },
  {
    title: (
      <div className="fw-550">
        <span className="text-gray">Lorem </span>
        <span>ipsum dolor</span>
      </div>
    ),
    content: (
      <div className="fw-300">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae
        veritatis dolor quas dolorum. At facere earum dolorem optio impedit
        incidunt delectus vel non sit nesciunt. Ullam, sunt similique! Qui,
        totam.
      </div>
    ),
  },
  {
    title: (
      <div className="fw-550">
        <span className="text-gray">Lorem </span>
        <span>ipsum dolor</span>
      </div>
    ),
    content: (
      <div className="fw-300">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus ad iste
        doloribus ut consequatur eaque facilis animi et, placeat laborum
        deserunt, in dolorem repudiandae cupiditate explicabo quis tempore sunt
        dolores?
      </div>
    ),
  },
  {
    title: (
      <div className="fw-550">
        <span className="text-gray">Lorem </span>
        <span>ipsum dolor</span>
      </div>
    ),
    content: (
      <div className="fw-300">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat hic
        voluptatum commodi sapiente deleniti totam pariatur velit, eveniet
        recusandae? Non exercitationem eaque omnis cum ducimus fugit voluptatum
        deleniti qui numquam?
      </div>
    ),
  },
];
