import React from "react";
import { Link } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Context
import { useGlobalState } from "../context/GlobalStateProvider";

// Components
import ContentDivider from "../components/common/ContentDivider";
import ProductBox from "../components/WhyMonolith/ProductBox";

// Images
import basketballImg from "../assets/images/fitness-sport-01.jpg";
import whiteLogo from "../assets/images/logo_white.png";
import gutHealthImg from "../assets/images/gut-health.png";
import weightMgmtImg from "../assets/images/weight_management.png";
import cogHealthImg from "../assets/images/cognitive_health.png";
import gradientBgImg from "../assets/images/gradient_bg.png";
import lemonBoxImg from "../assets/images/box_MCT Essentials_03.png";
import leanBoxImg from "../assets/images/box_MCT LeanEssentials_02.png";
import proBoxImg from "../assets/images/box_MCT ProEssentials_03.png";
import sustainImg from "../assets/images/sustainability-01.jpg";

const foundationalBenefits = [
  {
    name: "GUT HEALTH",
    desc: "So much can be said about gut health, but oftentimes its importance is overshadowed by topics such as cardiovascular health, fitness and endurance (don’t forget aesthetics and looking good too!), the immune system, and even anti-ageing. However, be enlightened or reminded while you read this that the majority of illnesses, diseases and underlying health problems stems from the gut. Unhealthy and imbalance gut microbiome is linked to a multitude of chronic diseases and metabolic disorders, and Monolith Nutrition’s product solutions tackle this head-on.",
    img: gutHealthImg,
  },
  {
    name: "WEIGHT MANAGEMENT",
    desc: "The entertainment industry, social media, and peer pressure has played a big (and oftentimes negative) role in the perception of what constitutes a healthy and aesthetically pleasing body or an “ideal weight”. Crash diets, exercise fads, the “next big thing” hype and trends, and even various weight loss drugs sell the illusion of an ideal body type. Weight loss is just one aspect in overall physical health, and Monolith Nutrition addresses the importance of a realistic, sustainable, and most importantly, healthy solutions.",
    img: weightMgmtImg,
  },
  {
    name: "COGNITIVE (BRAIN) HEALTH",
    desc: "The importance of cognitive health relating to the brain cannot be overlooked – directly aiding our ability to perform at peak performance both across our daily activities both complex and basic. In light of newer and deeper research into the role of ketones in the human body, cognitive health represents one of Monolith Nutrition’s foundational benefits that we put into focus into nutritional research and and product development.",
    img: cogHealthImg,
  },
];

const productBoxes = [
  {
    element: <div className="font-bold productLabel">MCT ESSENTIALS</div>,
    img: lemonBoxImg,
    name: "MCT ESSENTIALS",
  },
  {
    element: (
      <div className="font-bold productLabel">
        MCT <span className="fw-300">LEAN</span>ESSENTIALS
      </div>
    ),
    img: leanBoxImg,
    name: "MCT LEANESSENTIALS",
  },
  {
    element: (
      <div className="font-bold productLabel">
        MCT <span className="fw-300">PRO</span>ESSENTIALS
      </div>
    ),
    img: proBoxImg,
    name: "MCT PROESSENTIALS",
  },
];

function WhyMonolith() {
  const { isMobile } = useGlobalState();

  return (
    <div className={"whyPage"}>
      <div
        className="parallax short"
        style={{ backgroundImage: `url("${basketballImg}")` }}
      ></div>

      <div className="container">
        <div className={"contentSpacing"}>
          <div className="big-quote text-center text-uppercase m-b-30">
            WHY MONOLITH NUTRITION?
          </div>
          <div className="paragraph text-center">
            <div className="m-b-20">Not all products are created equally.</div>
            <div className="m-b-20">
              Monolith Nutrition began with an idea built around foundational
              nutrition that could stand the test of time irrelevant of trends,
              fads, and even habits.{" "}
              <span className="font-bold">
                We believe that an ideal nutritional supplement product must be
                beneficial, relevant, and enduring to the consumer – you.
              </span>{" "}
              This is our emboldening mission that guided our brand’s product
              science, formulations, features, benefits, and even its pricing
              and marketing strategies.
            </div>
            <div className="m-b-20">
              Monolith Nutrition’s brand partners come from entrepreneural and
              business backgrounds across various disciplines and skill sets,
              but we all one thing in common – we consume nutritional
              supplements on a daily basis and understand without a shadow of a
              doubt that health is wealth.
            </div>
          </div>
        </div>
      </div>

      <div className={`foundationalBenefits p-relative text-white`}>
        <div className="primary-bg">
          <div className="container">
            <div className="big-quote text-center m-b-30">
              3 FOUNDATIONAL BENEFITS
            </div>
            <div className="text-center m-b-30">
              Monolith Nutrition is built upon the goals and purpose of
              addressing three foundational health aspects by offering the
              following three foundational benefits:
            </div>
            <div
              className={`flex flex-col gap-20 text-uppercase foundationalList`}
            >
              {foundationalBenefits.map((el) => (
                <div
                  className={`flex gap-10 a-i-center foundationalListItem`}
                  key={el.name}
                >
                  <img width={54} src={whiteLogo} alt="logo" />
                  <div>{el.name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={`benefitsInfo p-relative`}>
        <div className="container-sm">
          {foundationalBenefits.map((el, i, arr) => (
            <ContentDivider
              element={el}
              index={i}
              arr={arr}
              key={`${el.title}-${i}`}
            />
          ))}
        </div>
      </div>

      <div
        className="productSection"
        style={{ backgroundImage: `url("${gradientBgImg}")` }}
      >
        <div className={"productBoxes"}>
          {isMobile && (
            <Swiper
              modules={[Navigation]}
              navigation={true}
              className="mySwiper commonMobSwiper"
            >
              {productBoxes.map((el, i) => (
                <SwiperSlide key={`mobile-product-box-${i}`}>
                  <ProductBox item={el} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          {!isMobile &&
            productBoxes.map((el, i) => (
              <ProductBox item={el} key={`product-box-${i}`} />
            ))}
        </div>
      </div>

      <div className={`shopSection text-center`}>
        <div className="container">
          <div className="big-title m-b-30">
            COMBINING SCIENCE, ART AND A WHOLE LOT OF{" "}
            <span className="text-red">PASSION</span>
          </div>
          <div className="paragraph m-b-30">
            Great products don’t just appear out from thin air. It took close to
            three years for us to formulate our first product MCT Essentials by
            combining nutritional science and knowledge with and manufacturing
            art and quality standards. The ultimate result is you get a highly
            nutritious quality and affordable product with the taste,
            mixability, and consistency that can proudly stand and endure the
            test of time.
            <br />
            <br />
            As experts in the use and application of MCT (Medium-chain
            triglycerides), Monolith Nutrition is confident in changing and
            improving the way and purpose of nutritional supplementation.
          </div>
          <button className="btn btn-primary btn-large">
            SHOP FOR THE BEST MCT PRODUCTS
          </button>
        </div>
      </div>

      <div className={`sloganSection p-relative text-white`}>
        <div className={"sloganBg"}>
          <div className="container">
            <div className="big-quote font-bold text-center m-b-30">
              SO MUCH MORE THAN JUST A BRAND SLOGAN
            </div>
            <div className="big-quote font-bold text-center m-b-30">
              <div className="stylishTitle">TRANSFORM</div>
              <div className="stylishTitle">INSIDE OUT</div>
            </div>
            <div className="text-center paragraph">
              Monolith Nutrition’s slogan is also a motto that we as a brand and
              company live and breathe for – committing to the understanding and
              underlying importance of making changes first from the inside. And
              when positive changes take place inside us, the outside – not just
              our appearance but also our external environment – will naturally
              follow suit towards the better.
            </div>
          </div>
        </div>
      </div>

      <div className={`contactSection p-relative text-center`}>
        <div className="contactBg">
          <div className="container">
            <div className="big-quote font-bold text-center m-b-30">
              SUSTAINABILITY: IT’S NOT A CHOICE. IT’S AN ABSOLUTE REQUIREMENT
            </div>
            <div className="text-center paragraph m-b-30">
              All Monolith Nutrition products are made with not just sustainable
              materials, but also principles. Our product boxes are made from
              recycled paper packaging material with printing that use
              environementally friendly vegetable-based ink, and we do not use
              plastic wrap on our packaging. No cutting corners. No half-baked
              ideas. No half-heartedness. All or nothing.
              <br />
              <br />
              Concerned about sustainability and want to know more? Do not
              hesitate to touch base with us!
            </div>
            <Link to="/contact" className="btn btn-primary btn-large">
              GET IN TOUCH
            </Link>
          </div>
        </div>
      </div>

      <div className={`beachSection p-relative`}>
        <div
          className="beachImg"
          // src={sustainImg}
          style={{ backgroundImage: `url("${sustainImg}")` }}
          // alt="beach"
        ></div>
      </div>
    </div>
  );
}

export default WhyMonolith;
