import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useGlobalState } from "../../context/GlobalStateProvider";

function Banner({ img, type }) {
  const { isMobile } = useGlobalState();
  const location = useLocation();

  return (
    <div
      className={`bannerHome ${
        location.pathname === "/" ? "essentialBanner" : "leanBanner"
      }`}
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="container p-relative full-height">
        {type === "essentials" && (
          <div className="essentialBannerTextContainer">
            <div className="essentialBannerText font-bold">
              {!isMobile && (
                <>
                  Improve Gut Health,
                  <br />
                  Manage weight, and
                  <br />
                  boost brain health
                </>
              )}

              {isMobile && (
                <>Improve Gut Health, Manage weight, and boost brain health</>
              )}
            </div>

            <Link
              to="/mct/essentials"
              className="btn btn-primary btn-large text-uppercase"
            >
              Get Yours
            </Link>
          </div>
        )}

        {type === "lean" && (
          <div className="leanEssentialBannerTextContainer">
            <div className="leanEssentialBannerText font-bold">
              MCT + Protein + Great taste
            </div>

            <div className="fw-300 m-b-20 fs-25">
              It's not math, but still a formula for success!
            </div>

            <Link
              to="/mct/leanessentials"
              className="btn btn-primary btn-large text-uppercase"
            >
              Get Yours
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default Banner;
