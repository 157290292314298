import React from "react";
import { Link } from "react-router-dom";

function CategoryContentItem({ content }) {
  const {
    title,
    author: { name },
    desc,
    path,
    banner,
  } = content;
  return (
    <div className="border-bottom-1 flex flex-col a-i-start j-c-sb p-b-20 gap-50">
      <div className="flex flex-col a-i-start">
        <img
          className="full-width m-b-5 max-h-300 object-cover"
          src={banner}
          alt={title}
        />
        <div className="flex flex-col a-i-start gap-20">
          <div className="font-bold categoryContentDesc">
            <div className="text-uppercase fs-25">{title}</div>
            <div className="text-gray">by {name}</div>
          </div>

          <div className="fw-300">{desc}</div>
        </div>
      </div>

      <Link
        to={`/community-blog/${path}`}
        className="text-red text-uppercase cursor-pointer"
      >
        Learn More
      </Link>
    </div>
  );
}

export default CategoryContentItem;
