import React from "react";

function CategoryList({ category, activeCategory, setActiveCategory }) {
  const { value, label } = category;

  return (
    <div
      className={`${
        activeCategory === value ? "primary-bg text-white" : ""
      } p-x-10 p-y-5 b-r-5 cursor-pointer fw-300 fs-14`}
      onClick={() => setActiveCategory(value)}
    >
      {label}
    </div>
  );
}

export default CategoryList;
