import React from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

function GreatExpBetterPpl({ happyUsers }) {
  return (
    <>
      <div className="font-bold text-uppercase m-b-40 fs-35 text-center">
        <span className="text-red">Great</span> Experiences,{" "}
        <span className="text-red">better</span> people
      </div>

      <div className="happyUsersSwiper">
        <Swiper
          modules={[Navigation]}
          navigation={{
            nextEl: `.swiper-custom-next`,
            prevEl: `.swiper-custom-prev`,
          }}
          loop={true}
          className="mySwiper"
        >
          {happyUsers.map((user, idx) => (
            <SwiperSlide key={`happy-${idx}`}>
              <div className="happyUserSwipeItem">
                <img
                  className="max-w-400 object-cover"
                  src={user.img}
                  alt="happy user"
                />

                <div className="flex flex-col gap-30">
                  <div className="flex flex-col gap-10">
                    <div className="font-bold fs-30 text-uppercase">
                      {user.title}
                    </div>

                    <div className="fw-300 fs-20">{user.desc}</div>
                  </div>

                  <div className="flex flex-col gap-5">
                    <div className="font-bold fs-20">{user.username}</div>
                    <div className="fw-300">{user.address}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-custom-prev swiper-custom-nav cursor-pointer"></div>
        <div className="swiper-custom-next swiper-custom-nav cursor-pointer"></div>
      </div>
    </>
  );
}

export default GreatExpBetterPpl;
