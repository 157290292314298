import React from "react";

import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGlobalState } from "../../context/GlobalStateProvider";

function SachetIngredient({ titleImg, swiperArray, children }) {
  const { isMobile } = useGlobalState();
  return (
    <>
      <div className="container">
        <div className="sachetTitle m-b-50">
          <div className="text-uppercase text-right">{children}</div>

          <img className="max-w-300" src={titleImg} alt="sachet" />
        </div>
      </div>

      <Swiper
        modules={[Pagination]}
        slidesPerView={isMobile ? 1 : 5}
        pagination
        spaceBetween={30}
        className="mySwiper p-b-50 ingredientSwiper"
      >
        {swiperArray.map((ingredient, idx) => (
          <SwiperSlide key={`ing-${idx}`}>
            <div className="text-center">
              <img
                className="full-width max-w-250 m-b-10"
                src={ingredient.img}
                alt={ingredient.title}
              />
              <div className="font-bold text-uppercase">{ingredient.title}</div>
              {ingredient.body && (
                <div className="fw-300">{ingredient.body}</div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default SachetIngredient;
