import React from "react";
import { WhatsApp, FacebookRounded, LinkedIn } from "@mui/icons-material";
import { LinkOutlined } from "@ant-design/icons";
import { message } from "antd";

function SocialMedia({ url }) {
  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    whatsapp: `https://api.whatsapp.com/send?text=${url}`,
    linkedin: `https://www.linkedin.com/feed/?shareActive&mini=true&text=${url}`,
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      message.success("Link copied");
    } catch (error) {
      message.error(`Failed to copy text: ${error}`);
    }
  };

  return (
    <div className="flex a-i-center gap-10">
      Share it!
      <FacebookRounded
        style={{
          width: "30px",
          height: "30px",
          color: "#0866ff",
          cursor: "pointer",
        }}
        onClick={() => window.open(shareLinks.facebook, "_blank")}
      />
      <WhatsApp
        style={{
          width: "30px",
          height: "30px",
          color: "#25D366",
          cursor: "pointer",
        }}
        onClick={() => window.open(shareLinks.whatsapp, "_blank")}
      />
      <LinkedIn
        style={{
          width: "30px",
          height: "30px",
          color: "#0077B5",
          cursor: "pointer",
        }}
        onClick={() => window.open(shareLinks.linkedin, "_blank")}
      />
      <LinkOutlined
        style={{
          fontSize: "25px",
          color: "#777",
          cursor: "pointer",
        }}
        onClick={handleCopy}
      />
    </div>
  );
}

export default SocialMedia;
