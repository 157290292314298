import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";

// Constants
import {
  testProductList,
  productBenefits,
  productBenefitsDetailsAccordion,
} from "../configs/constantData";

// API
import { addToCart } from "../ducks/services";
import { getUserProfile } from "../ducks/actions";

// Context
import { useGlobalState } from "../context/GlobalStateProvider";

// Antd
import { Breadcrumb, message } from "antd";

// Components
import ProductShippingInfo from "../components/ProductItem/ProductShippingInfo";
import CustomAccordion from "../components/ProductItem/CustomAccordion";
import QtyEditor from "../components/common/QtyEditor";
import CartDrawer from "../components/CartDrawer";
import FloatAddToCart from "../components/ProductItem/FloatAddToCart";

const validPath = ["essentials", "leanessentials"];

function TestProductItem({ openCart, setOpenCart }) {
  const [qty, setQty] = useState(1);
  const [curOpen, setCurOpen] = useState(null);
  const [showFloatingAddCartBtn, setShowFloatingAddCartBtn] = useState(false);
  const paramName = useParams().name;
  const { setLoading, isLoggedIn } = useGlobalState();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.global.userData);
  const cartAmount = userData?.cart?.amount;

  const selectedProduct = testProductList?.[paramName];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!validPath.includes(paramName)) {
      navigate("/page-not-found", { replace: true });
      return;
    }
    setQty(1);
    setCurOpen(null);
  }, [paramName, navigate]);

  const minusQty = () => {
    if (qty === 1) return;
    setQty((quantity) => quantity - 1);
  };

  const changeQty = (val) => {
    if (val.length === 1 && val === "0") {
      setQty("");
    } else {
      setQty(val);
    }
  };

  const addQty = () => {
    setQty((quantity) => quantity + 1);
  };

  const handleAddToCart = async () => {
    if (!isLoggedIn()) {
      message.error("Please login to continue.");
      navigate("/login");
      return;
    }

    let productQty = 1;
    if (Number(qty) > 0) {
      productQty = Number(qty);
    } else {
      setQty(1);
    }

    if (
      productQty * selectedProduct.price > 3000 ||
      Number(cartAmount) + productQty * selectedProduct.price > 3000
    ) {
      message.error(
        "Exceed maximum RM3000 per transaction. Please reduce your quantity."
      );
      return;
    }

    setLoading(true);

    const payload = {
      product_id: selectedProduct?.id,
      quantity: productQty,
      to_add: "1",
    };

    await addToCart(payload, userData?.cart?.amount)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          dispatch(getUserProfile());
          setOpenCart(true);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseCart = () => {
    setOpenCart(false);
    setQty(1);
  };

  // Show floating add to cart btn start
  const showAddToCartBtn = () => {
    if (
      document.body.scrollTop > 800 ||
      document.documentElement.scrollTop > 800
    ) {
      setShowFloatingAddCartBtn(true);
    } else {
      setShowFloatingAddCartBtn(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", showAddToCartBtn);

    return () => {
      window.removeEventListener("scroll", showAddToCartBtn);
    };
  }, []);
  // Show floating add to cart btn end

  return (
    <div className="test-product-page">
      <div className="container">
        <div className="breadcrumbContainer m-y-30">
          <Breadcrumb separator=">" items={selectedProduct?.breadcrumbName} />
        </div>

        <div className="productBrief">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            navigation={false}
            pagination
            loop
            autoplay
            className="mySwiper productImgsSwiper"
          >
            {selectedProduct?.productImages.map((el, i) => (
              <SwiperSlide key={`product-item-img-${i}`}>
                <div className="productSwiperImgWrapper">
                  <img className="displayImg" src={el} alt="banner" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="productInfoContainer">
            <div className="titleContent m-b-30">
              {selectedProduct?.productName}
              {/* <div className="big-title fs-30 fw-500">
                RM{selectedProduct?.price.toFixed(2)}
              </div> */}
            </div>
            <div className="productInfoDesc paragraph">
              <div className="font-bold">
                {selectedProduct?.productDescHead}
              </div>
              <div>{selectedProduct?.productDescBody}</div>
            </div>
            <div className="productCustomize flex flex-col gap-20 m-b-30">
              <div className="flex j-c-sb a-i-center gap-10 font-bold customoizeOptions">
                <div>Package Size</div>
                <div className="customoizeOption">{selectedProduct?.size}</div>
              </div>

              <div className="flex j-c-sb a-i-center gap-10 font-bold customoizeOptions">
                <div>Flavour</div>
                <div className="customoizeOption">
                  {selectedProduct?.flavour}
                </div>
              </div>
            </div>

            <div className="productCta m-b-30">
              <QtyEditor
                qty={qty}
                minusQty={minusQty}
                addQty={addQty}
                changeQty={changeQty}
                min={1}
              />
              <button
                className="btn btn-primary btn-large text-uppercase"
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>
            </div>

            <div className="flex j-c-sb a-i-end m-b-20">
              <div className="text-uppercase fw-300">Total</div>
              <div className="fw-500 fs-30">
                RM{Number(qty * selectedProduct.price).toFixed(2)}
              </div>
            </div>

            <ProductShippingInfo />
          </div>
        </div>

        <div className="productBenefits m-b-50">
          <div className="productBenefitsGrid">
            {productBenefits.map((product) => (
              <div className="productBenefitsGridItem" key={product.title}>
                <div className="flex flex-col gap-10">
                  {product.icon}
                  <div className="fw-550 fs-18">{product.title}</div>
                </div>
                <div className="fw-300">{product.body}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="accordionContent m-b-50">
          {selectedProduct?.accordionData.map((el, i) => (
            <CustomAccordion
              data={el}
              num={i + 1}
              curOpen={curOpen}
              onCurOpen={setCurOpen}
              key={`${el.title}-${i}`}
            />
          ))}
        </div>

        <div className="productFeature">
          <div className="big-title text-uppercase text-center m-b-50">
            Big Things Come In These Small Packages!
          </div>

          <div className="productFtImgSec m-b-50">
            <div className="gridContent">
              {selectedProduct?.productFeatureImages.map((el, i) => (
                <div
                  className="gridItem"
                  key={`product feature ${i}`}
                  style={
                    el?.needBorder
                      ? { border: "1px solid var(--border-grey)" }
                      : {}
                  }
                >
                  <img src={el.img} alt={el.alt} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="accordionContent m-b-50">
          {productBenefitsDetailsAccordion?.map((el, i) => (
            <CustomAccordion
              data={el}
              num={i + 1}
              curOpen={curOpen}
              onCurOpen={setCurOpen}
              key={`${el.title}-${i}`}
            />
          ))}
        </div>

        <div className="m-b-50">
          <div className="fw-550 m-b-20">Additional Measures</div>
          <div className="paragraph">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tempora
            minima in ratione explicabo libero impedit. Aut voluptatibus
            voluptate nobis in eveniet, corporis incidunt illum sequi odit
            veritatis maiores unde quo.
            <br />
            <br />
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aperiam
            nesciunt quod, pariatur voluptas assumenda fugiat voluptates
            suscipit adipisci non sapiente mollitia magnam? Voluptatum quia et
            ipsam, eos explicabo tempora corporis!
          </div>
        </div>
      </div>

      <CartDrawer onOpenCart={openCart} onHandleCloseCart={handleCloseCart} />

      {showFloatingAddCartBtn && (
        <FloatAddToCart handleAddToCart={handleAddToCart} />
      )}
    </div>
  );
}

export default TestProductItem;
