import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGlobalState } from "../context/GlobalStateProvider";

// Constant Data
import { isPro } from "../configs/constantData";

// API
import { getOption } from "../ducks/actions";
import { submitContactForm } from "../ducks/services";

import { Form, message } from "antd";

// Components
import ContentDivider from "../components/common/ContentDivider";
import CustomInput from "../components/common/CustomInput";

// Images
// import lifeStyleImg1 from "../assets/images/lifestyle-01.jpg";
// import lifeStyleImg2 from "../assets/images/lifestyle-02.jpg";
import affTopImg from "../assets/images/affTopImg.png";
import affBtmImg from "../assets/images/affBtmImg.png";
import lifeStyleImg3 from "../assets/images/lifestyle-03.jpg";

const affBenefits = [
  {
    name: "BE AMONG LIKE-MINDS",
    desc: "Join us and get ready to establish wholesome, sustainable, and meaningful relationships. As a Lifestyle Affiliate, you unlock and gain access to a club and network of like-minded individuals who are passionate for success in health and relationships, centralised around a profitable program that emphasises the triple bottom line – People, Planet, and Prosperity.",
  },
  {
    name: "ATTRACTIVE COMMISSION-BASED PASSIVE INCOME",
    desc: "With some of the most competitive commission rates, expect to build and enjoy the rewards of a passive source of income derived from the promotion of timeless quality nutritional and lifestyle products that are value-priced and affordable. To walk with and assist you all the way, gain access to detailed reports, tracking, promotional and seasonal coupons, product and lifestyle improvement and training, creative assets and materials for promotion, health and wellness talk sessions, and more!",
  },
  {
    name: "TAP INTO A NETWORK OF EXPERIENCE AND EXPERTISE",
    desc: "Becoming a Lifestyle Affiliate opens up connections and relationships with our network of doctors, pharmacists, physicians, coaches, and business professionals who are also Lifestyle Affiliates who share a passion for good health and better living.",
  },
  {
    name: "LIFETIME LEARNING AND A HUMAN TOUCH KNOWLEDGE BASE",
    desc: "Don’t just limit yourself to the Internet, and complete your health and fitness journey with real-life experiences from real people whom you can directly connect and relate to for knowledge application and guidance. Learn from those who have “been there and done that” to support your goals.",
  },
];

function BecomeAff() {
  const { setLoading } = useGlobalState();
  const [isContentCreator, setIsContentCreator] = useState("1");
  const [isBlogWriter, setIsBlogWriter] = useState("1");

  const dispatch = useDispatch();
  const optionList = useSelector((state) => state.global.optionList);
  const [form] = Form.useForm();

  const countryDropdown = optionList?.countries?.map((item) => ({
    label: item?.name,
    value: item?.name,
  }));

  useEffect(() => {
    dispatch(getOption());
  }, []);

  const formFields = [
    {
      label: "Name",
      name: "name",
      type: "text",
      required: true,
      reqMsg: "Please insert name!",
      placeholder: "Please insert name",
    },
    {
      label: "Contact Number",
      name: "mobile",
      type: "text",
      required: true,
      reqMsg: "Please insert contact number!",
      placeholder: "Please insert contact number",
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      required: true,
      reqMsg: "Please insert email!",
      placeholder: "Please insert email",
    },
    {
      label: "Country",
      name: "country",
      type: "select",
      required: true,
      reqMsg: "Please select country!",
      options: countryDropdown,
      placeholder: "Country",
    },
    {
      label: "Are you a content creator?",
      name: "is_content_creator",
      type: "radio",
      required: true,
      reqMsg: "Required",
      radioOptions: [
        { radioLabel: "Yes", radioValue: "1" },
        { radioLabel: "No", radioValue: "0" },
      ],
      value: isContentCreator,
      onChange: (e) => {
        setIsContentCreator(e.target.value);
      },
    },
    {
      label: "Are you a blog writer?",
      name: "is_blog_writer",
      type: "radio",
      required: true,
      reqMsg: "Required",
      radioOptions: [
        { radioLabel: "Yes", radioValue: "1" },
        { radioLabel: "No", radioValue: "0" },
      ],
      value: isBlogWriter,
      onChange: (e) => {
        setIsBlogWriter(e.target.value);
      },
    },
    {
      label: "Comments",
      name: "comments",
      type: "textarea",
      required: true,
      reqMsg: "Please select comments!",
      placeholder: "Please insert comments",
    },
  ];

  const onFinish = async (val) => {
    setLoading(true);

    const payload = {
      ...val,
      contact_type: "affiliate",
      is_pro: isPro,
      is_blog_writer: isBlogWriter,
      is_content_creator: isContentCreator,
    };

    await submitContactForm(payload)
      .then((res) => {
        // props.setLoading(false);
        if (res.data.code === 200) {
          message.success("Your feedback is submitted!");
          form.resetFields();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        // props.setLoading(false);
        message.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="affPage">
      <div
        className="parallax short"
        style={{ backgroundImage: `url("${affTopImg}")` }}
      ></div>

      <div className={`affIntroSection text-center`}>
        <div className="container">
          <div className="big-title m-b-30">BECOME A LIFESTYLE AFFILIATE</div>
          <div className="paragraph m-b-50">
            There’s never been a better time and reason to take charge, get
            involved, and tap into the opportunity of becoming a Monolith
            Nutrition Lifestyle Affiliate! Our quality products are some of the
            best tasting and effective foundational nutrition supplements that
            resonate and relate to everyday people who prioritise a holistic
            lifestyle of health and wellness. You are about to be rewarded in
            the business of improving other people’s health and lives through
            quality, affordable, honest, and enduring products.
          </div>
          <Link to="/contact" className="btn btn-primary btn-large m-b-50">
            BECOME A LIFESTYLE AFFILIATE
          </Link>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className={`contactForm m-b-100 text-left`}
            style={{
              maxWidth: 600,
              margin: "0 auto",
            }}
          >
            {formFields.map((el, i) => (
              <Fragment key={`contact-form-${i}`}>
                <CustomInput item={el} />
              </Fragment>
            ))}
            {/* <Row gutter={[5, 10]}>
            <Col span={24}>
              <Input placeholder="Name" size="large" />
            </Col>
            <Col span={6}>
              <Select
                defaultValue="60"
                options={[
                  {
                    value: "60",
                    label: (
                      <div className="flex gap-5 a-i-center">
                        <img width={24} src={malaysiaImg} alt="malaysia" />
                        60
                      </div>
                    ),
                  },
                ]}
                size="large"
                className="full-width"
              />
            </Col>
            <Col span={18}>
              <Input placeholder="Phone" size="large" />
            </Col>
            <Col span={24}>
              <div>Country</div>
              <Select
                className="full-width"
                defaultValue="Malaysia"
                options={[{ value: "Malaysia", label: "Malaysia" }]}
                size="large"
              />
            </Col>
            <Col span={24}>
              <button
                className="btn btn-primary btn-large full-width"
                type="submit"
              >
                Submit
              </button>
            </Col>
          </Row> */}

            <button
              className="btn btn-primary btn-large full-width"
              type="submit"
            >
              Submit
            </button>
          </Form>

          <img className="m-b-50" src={lifeStyleImg3} alt="life style" />

          <div className="paragraph">
            It’s free, quick, and easy to join and there are so many reasons why
            you should become a Monolith Nutrition Lifestyle Affiliate.
          </div>
        </div>
      </div>

      <div className={`affBenefitsInfo p-relative`}>
        <div className="container">
          {affBenefits.map((el, i, arr) => (
            <ContentDivider
              element={el}
              index={i}
              arr={arr}
              key={`${el.title}-${i}`}
            />
          ))}
        </div>
      </div>

      <div className={`sloganSection p-relative text-white text-center`}>
        <div className="sloganBg">
          <div className="container">
            <div className="big-quote font-bold m-b-30">
              SHARE AND PROMOTE GOOD HEALTH AND BETTER LIVING
            </div>
            <div className="paragraph m-b-30">
              How does the Monolith Nutrition Lifestyle Affiliate work? By just
              sharing the benefits of our products with others! With customised
              linking which can be easily shared electronically across chat,
              messengers, groups, social media, blogs, email, forums, and across
              the web, and you earn money on all qualifying purchases. Sweet and
              simple, plus don’t forget, you are also eligible for all future
              repeat orders from your customer!
            </div>
            <Link to="/contact" className="btn btn-sub btn-large">
              BECOME A LIFESTYLE AFFILIATE
            </Link>
          </div>
        </div>
      </div>

      <div className={`btmImgSection p-relative`}>
        <div
          className="btmImg"
          style={{ backgroundImage: `url("${affBtmImg}")` }}
        ></div>
      </div>
    </div>
  );
}

export default BecomeAff;
