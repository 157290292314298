import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import TopbarMenu from "./components/TopbarMenu";
import CartDrawer from "../CartDrawer";
import MobileMenu from "./components/MobileMenu";

// Context
import { useGlobalState } from "../../context/GlobalStateProvider";

// Icons
import {
  // SearchOutlined,
  ShoppingOutlined,
  UserOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import Logo from "./components/Logo";

const topbarMenu = [
  {
    title: "Shop",
    value: "shop",
    children: [
      {
        name: <div className={`links p-15 cursor-pointer`}>MCT ESSENTIALS</div>,
        link: "/mct/essentials",
      },
      {
        name: (
          <div className={`links p-15 cursor-pointer`}>
            MCT <span className="fw-300">LEAN</span>ESSENTIALS
          </div>
        ),
        link: "/mct/leanessentials",
      },
    ],
  },
  {
    title: "Why Monolith Nutrition?",
    value: "why",
    children: [
      {
        name: (
          <div className={`links p-15 cursor-pointer`}>
            Why Monolith Nutrition?
          </div>
        ),
        link: "/about/why-monolith-nutrition",
      },
      {
        name: (
          <div
            className={`links flex gap-10 a-i-center p-15 links cursor-pointer`}
          >
            <div>Absolutely MCT For Today And Tomorrow</div>
            {/* <DownOutlined /> */}
          </div>
        ),
        link: "/about/why-mct",
        // grandchildren: [
        //   {
        //     name: (
        //       <li className={`links p-15 cursor-pointer`}>
        //         MCT ESSENTIALS
        //       </li>
        //     ),
        //     link: "/mct/essentials",
        //   },
        //   {
        //     name: (
        //       <li className={`links p-15 cursor-pointer`}>
        //         MCT <span className="fw-300">LEAN</span>ESSENTIALS
        //       </li>
        //     ),
        //     link: "/mct/leanessentials",
        //   },
        // ],
      },
      {
        name: <div className={`links p-15 cursor-pointer`}>MCT Essentials</div>,
        link: "/",
      },
      {
        name: (
          <div className={`links p-15 cursor-pointer`}>
            MCT <span className="fw-300">LEAN</span>ESSENTIALS
          </div>
        ),
        link: "/about/leanessentials",
      },
    ],
  },
  {
    title: "Live Life & Learn",
    value: "learn",
    children: [
      {
        name: <div className={`links p-15 cursor-pointer`}>COMMUNITY BLOG</div>,
        link: "/community-blog",
      },
    ],
  },
  {
    title: "Get In Touch!",
    value: "contact",
    children: [
      {
        name: (
          <div className={`links p-15 cursor-pointer`}>
            Become a lifestyle affiliate
          </div>
        ),
        link: "/lifestyle-affiliate",
      },
      {
        name: <div className={`links p-15 cursor-pointer`}>Contact Us</div>,
        link: "/contact",
      },
    ],
  },
];

function Topbar({ isScrollingUp, openCart, setOpenCart }) {
  const [openMenu, setOpenMenu] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { isMobile, isLoggedIn } = useGlobalState();
  const location = useLocation();

  const userData = useSelector((state) => state.global.userData);

  const cartQty = userData?.cart?.cart_items?.reduce(
    (acc, cur) => acc + cur.qty,
    0
  );

  const handleOpenMenu = (val) => {
    if (openMenu === val) setOpenMenu(null);
    else setOpenMenu(val);
  };

  const handleOpenSubMenu = (e) => {
    e.stopPropagation();
    setOpenSubMenu(!openSubMenu);
  };

  const handleOpenCart = () => {
    setOpenCart(true);
  };

  const handleCloseCart = () => {
    setOpenCart(false);
  };

  const handleOpenMobileMenu = () => {
    setOpenMobileMenu(true);
  };

  const handleCloseMobileMenu = () => {
    setOpenMobileMenu(false);
  };

  useEffect(() => {
    if (!isMobile) setOpenMobileMenu(false);
  }, [isMobile]);

  return (
    <>
      <div
        className={`topbar p-y-20 ${
          location.pathname === "/" ||
          location.pathname === "/about/leanessentials"
            ? "bg-transparent text-black"
            : "bg-black text-white"
        } ${isScrollingUp ? "sticky bg-black text-white" : "p-relative"}`}
      >
        <div className={`topbarContainer container`}>
          <div className="logo flex a-i-center gap-20">
            {isMobile && <MenuOutlined onClick={handleOpenMobileMenu} />}
            <Link className="cursor-pointer" to="/">
              <Logo isScrollingUp={isScrollingUp} />
            </Link>
          </div>

          <div className={`topbarMenu text-uppercase fs-14`}>
            {topbarMenu.map((el, i) => (
              <TopbarMenu
                element={el}
                onOpenMenu={openMenu}
                onHandleOpenMenu={handleOpenMenu}
                onOpenSubMenu={openSubMenu}
                onHandleOpenSubMenu={handleOpenSubMenu}
                onSetOpenSubMenu={setOpenSubMenu}
                key={`topbarItem-${i}`}
                isScrollingUp={isScrollingUp}
              />
            ))}
          </div>

          <div className="profileContainer">
            {/* <SearchOutlined /> */}
            <Link to={`/${isLoggedIn() ? "account" : "login"}`}>
              <UserOutlined />
            </Link>
            <div className="p-relative cursor-pointer" onClick={handleOpenCart}>
              <ShoppingOutlined />
              {cartQty > 0 && <div className="cartQty">{cartQty}</div>}
            </div>
          </div>
        </div>
      </div>

      <CartDrawer onOpenCart={openCart} onHandleCloseCart={handleCloseCart} />
      <MobileMenu
        onOpenMobileMenu={openMobileMenu}
        onHandleCloseMobileMenu={handleCloseMobileMenu}
      />
    </>
  );
}

export default Topbar;
