import React from "react";
import { useLocation } from "react-router-dom";

// Images
import logo from "../../../assets/images/logo.png";
import logoBlack from "../../../assets/images/logo-black.png";

function Logo({ isScrollingUp }) {
  const location = useLocation();

  if (
    location.pathname === "/" ||
    location.pathname === "/about/leanessentials"
  ) {
    if (!isScrollingUp)
      return <img className="logo" src={logoBlack} alt="logo" />;
    else return <img className="logo" src={logo} alt="logo" />;
  }

  return <img className="logo" src={logo} alt="logo" />;
}

export default Logo;
